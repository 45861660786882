import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

function CustomerReviews() {
  const reviews = [
    {
      name: "John McCulling",
      role: "Pediatrician",
      review:
        "I had a fantastic experience with this lab testing service. Booking my appointment was seamless, and the results were delivered quickly and accurately.",
      image: "images/Ellipse 2.png",
    },
    {
      name: "Jane Doe",
      role: "Dentist",
      review:
        "I had a fantastic experience with this lab testing service. Booking my appointment was seamless, and the results were delivered quickly and accurately!",
      image: "images/Ellipse 2.png",
    },
    {
      name: "Jane Doe",
      role: "Dentist",
      review:
        "The service was excellent, and the staff was very helpful. I highly recommend it!",
      image: "images/Ellipse 2.png",
    },
    {
      name: "Jane Doe",
      role: "Dentist",
      review:
        "The service was excellent, and the staff was very helpful. I highly recommend it!",
      image: "images/Ellipse 2.png",
    },
  ];

  return (
    <div className="md:pb-20 lg:h-[550px] h-[500px] py-12 px-4 sm:px-8 lg:px-20 max-w-screen-2xl mx-auto relative">
      <div className="mx-auto max-w-screen-xl px-4 md:px-8">
        <h2 className="mb-4 text-center text-2xl font-bold font-museo md:mb-10 lg:text-3xl xl:mb-12">
          What our<span className="font1 ml-2">Customers Say?</span>
        </h2>

        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          navigation={{
            nextEl: ".next-btt",
            prevEl: ".prev-btt",
          }}
          breakpoints={{
            640: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            1024: { slidesPerView: 3 },
          }}
          modules={[Navigation]}
          className="mySwiper min-h-[50vh]"
        >
          {reviews.map((review, index) => (
            <SwiperSlide key={index}>
              <div className="flex flex-col gap-3 shadow-lg p-4 md:p-6 h-[260px]">
                <div className="flex items-center justify-center gap-0.5">
                  {Array(5)
                    .fill("")
                    .map((_, index) => (
                      <svg
                        key={index}
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-yellow-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    ))}
                </div>
                <p className="font-mont font10 md:text-xs text-sm lg:text-sm">
                  {review.review}
                </p>
                <div className="flex">
                  <img
                    src={review.image}
                    alt={review.name}
                    className="w-16 h-16 md:w-12 md:h-12 lg:w-20 lg:h-20 mr-4"
                  />
                  <div className="flex flex-col mt-2">
                    <span className="text-sm font-bold lg:text-base font-museo">
                      {review.name}
                    </span>
                    <p className="lg:text-sm text-sm md:text-xs font7 font-museo">
                      {review.role}
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="absolute left-1/2 md:bottom-8 bottom-6 pb-2 md:pb-5 transform -translate-x-1/2 flex space-x-8 z-20">
          <div className="prev-btt font6 md:p-3 p-2 rounded-full border-2 border-color1 font6 hover-color1 hover:text-white transition-al z-20">
            <FiArrowLeft size={22} />
          </div>
          <div className="next-btt md:p-3 p-2 rounded-full border-2 border-color1 font6 hover-color1 hover:text-white transition-all z-20">
            <FiArrowRight size={22} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerReviews;
