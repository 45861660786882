import React from "react";

const TrackProgress = () => {
  // Dummy data for the test stages
  const progressStages = [
    { name: "Test Confirmed", date: "01/08/24", isActive: true },
    { name: "Sample Collected", date: "02/08/24", isActive: false },
    { name: "Sample Processing", date: "04/08/24", isActive: false },
    { name: "Report Ready", date: "05/08/24", isActive: false },
    { name: "Delivered", date: "08/08/24", isActive: false },
  ];

  return (
    <div className="bg-white shadow-lg rounded-md p-6 mx-auto">
      <div className="flex justify-between mb-4 pt-2">
        <h3 className="text-xl font-semibold font-museo">Full Body Test</h3>
        <p className="text-lg font-semibold font-museo">₹ 1500</p>
      </div>

      <p className="font10 font-mont mb-4">Track Progress:</p>

      <div className="flex items-center  relative">
        {progressStages.map((stage, index) => (
          <div key={index} className="flex items-center w-full">
            {/* Circle */}
            <div className="flex flex-col items-center">
              <div
                className={`w-6 h-6 mb-2 rounded-full ${
                  stage.isActive ? "bg-secondary1 relative z-10" : "bg-gray-300"
                }`}
              ></div>
              {/* Stage Name */}
              <p className="text-sm font-medium font7 font-mont">
                {stage.name}
              </p>
              {/* Date */}
              <p className="text-xs font9 font-mont">{stage.date}</p>
            </div>

            {/* Line connecting the circles */}
            {index < progressStages.length - 1 && (
              <div className="absolute top-3 left-12 w-[700px] h-1 bg-gray-300"></div>
            )}
          </div>
        ))}{" "}
      </div>
    </div>
  );
};

export default TrackProgress;
