import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

const OurDirectors = () => {
  // Dynamic review data
  const reviewData = [
    {
      name: "Dr. Vykunta Raju K. N",
      role: "Pediatrician",
      location: "Delhi",
      image: "images/Ellipse 1.png",
      review:
        "I had a fantastic experience with this lab testing service. Booking my appointment was seamless, and the results were delivered quickly and accurately.",
    },
    {
      name: "Dr. John Doe",
      role: "Cardiologist",
      location: "Mumbai",
      image: "images/Ellipse 2.png",
      review:
        "The service was professional, and I received my results sooner than expected. I highly recommend them for medical tests.",
    },
    {
      name: "Dr. Jane Smith",
      role: "Dermatologist",
      location: "Bangalore",
      image: "images/Ellipse 3.png",
      review:
        "This lab provided excellent service and precise results. The staff was very helpful, and I had a smooth experience.",
    },
    {
      name: "Dr. Jane Smith",
      role: "Dermatologist",
      location: "Bangalore",
      image: "images/Ellipse 3.png",
      review:
        "This lab provided excellent service and precise results. The staff was very helpful, and I had a smooth experience.",
    },
  ];

  return (
    <div className="md:py-20 py-12 px-4 sm:px-8 lg:px-20 max-w-screen-2xl mx-auto relative">
      <div className="mx-auto max-w-screen-xl px-4 md:px-8">
        <h2 className="mb-4 text-center text-2xl font-bold font-museo md:mb-8 lg:text-3xl xl:mb-12">
          Our <span className="font1">Directors</span>
        </h2>

        {/* Swiper Carousel for Customer Reviews */}
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          navigation={{
            nextEl: ".next-bt",
            prevEl: ".prev-bt",
          }}
          breakpoints={{
            640: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            1024: { slidesPerView: 3 },
          }}
          modules={[Navigation]}
          className="mySwiper min-h-[40vh]"
        >
          {reviewData.map((review, index) => (
            <SwiperSlide key={index} className="">
              <div className="flex flex-col gap-3 shadow-xl p-4 md:p-3 lg:p-6 lg:h-[260px]">
                <div className="flex">
                  <img
                    src={review.image}
                    alt={review.name}
                    className="w-16 h-16 md:w-16 md:h-16 lg:w-20 lg:h-20 mr-4"
                  />
                  <div className="flex flex-col mt-2">
                    <span className=" text-sm font-bold lg:text-base font-euclid">
                      {review.name}
                    </span>
                    <p className=" lg:text-sm text-sm md:text-xs font7 font-euclid">
                      {review.role}
                    </p>
                    <p className=" text-xs font7 font-euclid">
                      {review.location}
                    </p>
                  </div>
                </div>
                <p className="font-euclid font10 text-sm md:text-xs lg:text-sm ">
                  {review.review}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Custom Navigation Buttons */}
        <div className="absolute left-1/2 -bottom-8 pb-4 transform -translate-x-1/2 flex space-x-8 mb-4">
          <div className="prev-bt font6 md:p-3 p-2 rounded-full border-2 border-color1 font6 hover-color1 hover:text-white transition-al">
            <FiArrowLeft size={22} />
          </div>
          <div className=" next-bt md:p-3 p-2 rounded-full border-2 border-color1 font6 hover-color1 hover:text-white transition-all">
            <FiArrowRight size={22} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurDirectors;
