import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";

const EditTest = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  // State for form fields
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [fastingTime, setFastingTime] = useState("");
  const [reportTime, setReportTime] = useState("");
  const [usedFor, setUsedFor] = useState("");
  const [instructions, setInstructions] = useState("");
  const [testCategory, setTestCategory] = useState("");
  const [labIds, setLabIds] = useState([]); // Selected lab IDs
  const [categories, setCategories] = useState([]);
  const [labs, setLabs] = useState([]); // All available labs
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [show, setShow] = useState(false); // Boolean for "Show on Home Page"
  const [loading, setLoading] = useState(true);
  const [loadingLabs, setLoadingLabs] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(true);

  // Fetch test data and associated labs
  useEffect(() => {
    const fetchTestData = async () => {
      try {
        const response = await axiosClient.get(`/tests/test/${id}`);

        const test = response.data.test;

        setTitle(test.title);
        setSubTitle(test.subTitle);
        setDescription(test.description);
        setPrice(test.price);
        setFastingTime(test.fastingTime);
        setReportTime(test.reportTime);
        setUsedFor(test.usedFor);
        setInstructions(test.instructions);
        setShow(test.show);
        setTestCategory(test.testCategory);
        setLabIds(test.labId || []); // Assuming `labId` is an array of lab IDs

        setLoading(false);
      } catch (error) {
        console.error("Error fetching test data:", error);
        setError("Failed to fetch test data.");
        setLoading(false);
      }
    };

    fetchTestData();
  }, [id]);

  // Fetch test categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosClient.get("test-categories/test/categories");
        setCategories(response.data.testCategory || []);
        setLoadingCategories(false);
      } catch (err) {
        setError("Failed to fetch test categories.");
        setLoadingCategories(false);
      }
    };

    fetchCategories();
  }, []);

  // Fetch all labs
  useEffect(() => {
    const fetchLabs = async () => {
      try {
        const response = await axiosClient.get("labs");
        setLabs(response.data.labs || []); // Adjust according to your API response structure
        setLoadingLabs(false);
      } catch (err) {
        setError("Failed to fetch labs.");
        setLoadingLabs(false);
      }
    };

    fetchLabs();
  }, []);

  // Handle checkbox changes for labs
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      // Add the lab ID to the array
      setLabIds([...labIds, value]);
    } else {
      // Remove the lab ID from the array
      setLabIds(labIds.filter((id) => id !== value));
    }
  };

  // Handle changes in the show dropdown
  const handleShowChange = (e) => {
    setShow(e.target.value === "true");
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate that at least one lab is selected
    if (labIds.length === 0) {
      setError("Please select at least one lab.");
      return;
    }

    const packageData = {
      title,
      subTitle,
      description,
      price,
      fastingTime,
      reportTime,
      usedFor,
      instructions,
      show,
      testCategory,
      labId: labIds, // Send selected lab IDs
    };

    try {
      await axiosClient.put(`/tests/test/${id}`, packageData);
      setSuccess("Test updated successfully!");
      setError("");

      setTimeout(() => {
        navigate("/admin/all-test");
      }, 1000);
    } catch (error) {
      console.error("Error updating test data:", error);
      setError(error.response ? error.response.data.message : "Failed to update test.");
      setSuccess("");
    }
  };

  if (loading || loadingCategories || loadingLabs) return <p>Loading...</p>;

  return (
    <>
      <SideBar />
      <Navbar />
      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="container pt-3">
          <div className="row top-barcolumn">
            <h5>Edit Test</h5>
            <div className="row nav-column">
              <div>
                <form className="row" onSubmit={handleSubmit}>

                  {/* Test Category */}
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Test Category</label>
                    <select
                      className="form-control"
                      value={testCategory}
                      onChange={(e) => setTestCategory(e.target.value)}
                      required
                    >
                      <option value="">Select Category</option>
                      {categories.map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Associated Labs as Checkboxes */}
                  <div className="col-lg-12 mb-3">
                    <label className="form-label">Associated Labs</label>
                    <div className="form-check">
                      {labs.map((lab) => (
                        <div key={lab._id} className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={`lab-${lab._id}`}
                            value={lab._id}
                            checked={labIds.includes(lab._id)}
                            onChange={handleCheckboxChange}
                          />
                          <label className="form-check-label" htmlFor={`lab-${lab._id}`}>
                            {lab.name} - {lab.city}, {lab.state}
                          </label>
                        </div>
                      ))}
                    </div>
                    {labs.length === 0 && <p>No labs available. Please add labs first.</p>}
                  </div>

                  {/* Test Title */}
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Test Title</label>
                    <input
                      type="text"
                      className="form-control"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="Test Title"
                      required
                    />
                  </div>

                  {/* Test Subtitle */}
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Test Subtitle</label>
                    <input
                      type="text"
                      className="form-control"
                      value={subTitle}
                      onChange={(e) => setSubTitle(e.target.value)}
                      placeholder="Test Subtitle"
                      required
                    />
                  </div>

                  {/* Description */}
                  <div className="col-lg-12 mb-3">
                    <label className="form-label">Description</label>
                    <textarea
                      className="form-control"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="Description"
                      rows="3"
                      required
                    ></textarea>
                  </div>

                  {/* Price */}
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Price</label>
                    <input
                      type="number"
                      className="form-control"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      placeholder="Price"
                      required
                      min="0"
                      step="0.01"
                    />
                  </div>

                  {/* Fasting Time */}
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Fasting Time (hours)</label>
                    <input
                      type="number"
                      className="form-control"
                      value={fastingTime}
                      onChange={(e) => setFastingTime(e.target.value)}
                      placeholder="Fasting Time"
                      required
                      min="0"
                    />
                  </div>

                  {/* Report Time */}
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Report Time (days)</label>
                    <input
                      type="number"
                      className="form-control"
                      value={reportTime}
                      onChange={(e) => setReportTime(e.target.value)}
                      placeholder="Report Time"
                      required
                      min="0"
                    />
                  </div>

                  {/* Used For */}
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Used For</label>
                    <input
                      type="text"
                      className="form-control"
                      value={usedFor}
                      onChange={(e) => setUsedFor(e.target.value)}
                      placeholder="Purpose"
                      required
                    />
                  </div>

                  {/* Instructions */}
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Instructions</label>
                    <input
                      type="text"
                      className="form-control"
                      value={instructions}
                      onChange={(e) => setInstructions(e.target.value)}
                      placeholder="Instructions"
                      required
                    />
                  </div>

                  {/* Show on Home Page as Dropdown */}
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Show on Home Page</label>
                    <select
                      className="form-control"
                      value={show.toString()} // Convert boolean to string for select value
                      onChange={handleShowChange}
                      required
                    >
                      <option value="">Select Option</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>

                  {/* Error and Success Messages */}
                  {error && <div className="col-lg-12 alert alert-danger">{error}</div>}
                  {success && <div className="col-lg-12 alert alert-success">{success}</div>}

                  {/* Submit Button */}
                  <div className="col-lg-12">
                    <button type="submit" className="btn btn-success">
                      Update Test
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditTest;
