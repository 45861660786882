import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import NewsLetter from "../landingPage/NewsLetter";
import Footer from "../layout/Footer";

const Payments = () => {
  const [activeTab, setActiveTab] = useState("pending");
  const [currentPage, setCurrentPage] = useState(1); // Set current page
  const itemsPerPage = 3; // Items per page

  const pendingPayments = [
    {
      id: 1,
      testName: "Full Body Test",
      orderId: "9898",
      date: "20/08/2024",
      amount: "₹ 1500",
      status: "Pending",
    },
    {
      id: 2,
      testName: "Full Body Test",
      orderId: "9898",
      date: "20/08/2024",
      amount: "₹ 1500",
      status: "Pending",
    },
    {
      id: 3,
      testName: "Full Body Test",
      orderId: "9898",
      date: "20/08/2024",
      amount: "₹ 1500",
      status: "Pending",
    },
    {
      id: 4,
      testName: "Blood Test",
      orderId: "7788",
      date: "15/08/2024",
      amount: "₹ 1000",
      status: "Pending",
    },
    {
      id: 5,
      testName: "Urine Test",
      orderId: "6699",
      date: "12/08/2024",
      amount: "₹ 800",
      status: "Pending",
    },
  ];

  const pastPayments = [
    {
      id: 1,
      testName: "Blood Test",
      orderId: "8898",
      date: "10/07/2024",
      amount: "₹ 1200",
      status: "Paid",
    },
    {
      id: 2,
      testName: "Cholesterol Test",
      orderId: "7766",
      date: "05/07/2024",
      amount: "₹ 900",
      status: "Paid",
    },
    {
      id: 3,
      testName: "Thyroid Test",
      orderId: "6677",
      date: "02/07/2024",
      amount: "₹ 1100",
      status: "Paid",
    },
    {
      id: 4,
      testName: "Urine Test",
      orderId: "5588",
      date: "01/07/2024",
      amount: "₹ 700",
      status: "Paid",
    },
  ];

  // Pagination logic
  const currentPayments =
    activeTab === "pending"
      ? pendingPayments.slice(
          (currentPage - 1) * itemsPerPage,
          currentPage * itemsPerPage
        )
      : pastPayments.slice(
          (currentPage - 1) * itemsPerPage,
          currentPage * itemsPerPage
        );

  const totalPayments =
    activeTab === "pending" ? pendingPayments.length : pastPayments.length;
  const totalPages = Math.ceil(totalPayments / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <Navbar />
      <Navbar2 />
      <div className="max-w-screen-2xl mx-auto md:py-14 lg:py-20 py-8 lg:px-20 md:px-12 px-4 ">
        <div className="flex border-2 border-gray-300 shadow-lg rounded-3xl">
          {/* Sidebar */}
          <Sidebar />

          {/* Main Content */}
          <main className="w-3/4 p-8">
            <div className="flex justify-between items-center">
              <h1 className="lg:text-2xl md:text-xl text-lg font-semibold font-museo font4 mr-2">
                Payments
              </h1>
            </div>

            <div className="flex border-b-2 my-6">
              <button
                onClick={() => {
                  setActiveTab("pending");
                  setCurrentPage(1); // Reset to page 1 when switching tabs
                }}
                className={`py-2 px-4 text-lg font-semibold ${
                  activeTab === "pending"
                    ? "border-b-2 border-colorb font-museo"
                    : "font7"
                }`}
              >
                Pending Payments
              </button>
              <button
                onClick={() => {
                  setActiveTab("past");
                  setCurrentPage(1); // Reset to page 1 when switching tabs
                }}
                className={`py-2 px-4 text-lg font-semibold ${
                  activeTab === "past"
                    ? "border-b-2 border-colorb font-museo"
                    : "font7"
                }`}
              >
                Past Payments
              </button>
            </div>

            {/* Tab Content */}
            <div className="space-y-6">
              {currentPayments.map((payment) => (
                <div key={payment.id} className="p-6 rounded-md shadow-lg">
                  <div className="flex justify-between">
                    <div>
                      <h2 className="text-lg font-semibold font-museo mb-2">
                        {payment.testName}
                      </h2>
                      <p className="text-sm font7 font-semibold font-mont mb-2">
                        Order Id:{" "}
                        <span className="font-semibold bg-[#DF413299] px-2 py-1 ml-2 rounded-md text-white text-xs font-museo">
                          {payment.orderId}
                        </span>
                      </p>
                      <p className="text-sm font7 font-semibold font-mont">
                        Date of Order: {payment.date}
                      </p>
                    </div>
                    <div className="text-right">
                      <h2 className="text-lg font-semibold mb-2 font-museo">
                        {payment.amount}
                      </h2>
                      <p className="text-sm font7 font-semibold font-mont">
                        Status:{" "}
                        <span
                          className={`text-sm font-semibold ${
                            payment.status === "Pending"
                              ? "font-semibold bg-[#DF413299] px-2 py-1 ml-2 rounded-md text-white text-xs font-museo"
                              : "font-semibold bg-[#1E4FA699] px-2 py-1 ml-2 rounded-md text-white text-xs font-museo"
                          }`}
                        >
                          {payment.status}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}

              {/* Pagination Buttons */}
              <div className="flex justify-between mt-4">
                <button
                  className="border-color2 font4 hover-color2 hover:text-white px-3 py-2 rounded-md font-museo disabled:opacity-50"
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  Prev
                </button>
                <button
                  className="border-color2 font4 hover-color2 hover:text-white px-6 py-2 rounded-md font-museo  disabled:opacity-50"
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </main>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
};

export default Payments;
