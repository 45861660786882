import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import axiosClient from "../../axios-client";

const TopOffers = () => {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await axiosClient(`/tests/tests`);
        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }
        const data = await response.data;
        setOffers(data.test || []);
      } catch (error) {
        setError("Failed to fetch offers");
        console.error("There was a problem with the fetch operation:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOffers();
  }, []);

  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;
  if (offers.length === 0)
    return <div className="text-center">No offers available</div>;

  return (
    <div className="md:py-24 py-12 px-4 sm:px-8 lg:px-20 max-w-screen-2xl mx-auto">
      <h2 className="lg:text-3xl md:text-2xl text-xl font-bold text-center md:mb-8 font-museo">
        Check Our <span className="font1">Top Offers</span>
      </h2>

      {/* Swiper Carousel */}
      <div className="max-w-screen-2xl mx-auto relative lg:mt-20 mt-12">
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          navigation={{
            nextEl: ".btn-next",
            prevEl: ".btn-prev",
          }}
          breakpoints={{
            640: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            1024: { slidesPerView: 3 },
          }}
          modules={[Navigation]}
          className="mySwiper"
        >
          {offers
            .filter((pkg) => pkg.show === true)
            .map((offer, index) => (
              <SwiperSlide key={index}>
                <div className="w-full sm:w-80 md:w-[230px]  lg:w-[100%] md:h-[330px] bg-card2 border border-gray-300 rounded-lg shadow-lg lg:pl-5 pl-1 pt-3">
                  <div className="w-full h-full  p-2 flex flex-col justify-between relative">
                    <Link to={"/testBrowse"}>
                      <h3 className="lg:text-xl text-lg font-semibold font-museo font4 mb-2 lg:w-[60%] lg:py-4">
                        {offer.title}
                        <span className="font6">{offer.title2}</span>
                      </h3>
                    </Link>
                    <p className="lg:text-sm text-xs font4 font-medium mb-2 font-mont lg:w-[50%] md:w-full w-[80%] line-clamp-3">
                      {offer.description}
                    </p>
                    <div className="flex lg:flex-row md:flex-col flex-row justify-between lg:items-center text-xs font7 font-mont lg:mb-4 mb-2 w-[50%]">
                      <span className="font6">
                        {offer.parameters}5{" "}
                        <span className="font7">Parameters</span>
                      </span>
                      <span className="font6">
                        {offer.reportTime}{" "}
                        <span className="font7">Reports</span>
                      </span>
                    </div>
                    <div className="flex  justify-between items-center mb-4 w-[50%] font-museo">
                      <div className="bg-white lg:py-2 lg:px-4 p-1 rounded-lg shadow-sm">
                        <span className="lg:text-lg text-sm font-semibold font6">
                          ₹{offer.price}
                        </span>
                        <span className="font5 lg:text-sm text-xs font-medium ml-2">
                          60% off
                        </span>
                      </div>
                    </div>

                    <img
                      src="young.png"
                      alt="Offer"
                      className="lg:w-[52%] w-32 h-auto absolute md:bottom-8 bottom-6 right-0 z-10"
                    />
                    <img
                      src="Vector 7354.png"
                      alt="Package"
                      className="lg:w-[48%] md:w-32 w-36 h-auto absolute bottom-2 right-0 z-0"
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>

        {/* Custom Navigation Buttons */}
        <div className="absolute left-1/2 -bottom-20  transform -translate-x-1/2 flex space-x-4 mb-4 md:mb-1">
          <div className="btn-prev font6 md:p-3 p-2 rounded-full border-2 border-color1 font6 hover-color1 hover:text-white transition-al">
            <FiArrowLeft size={22} />
          </div>
          <div className="btn-next md:p-3 p-2 rounded-full border-2 border-color1 font6 hover-color1 hover:text-white transition-all">
            <FiArrowRight size={22} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopOffers;
