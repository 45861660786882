import React from "react";

function NewsLetter() {
  return (
    <div className="bg-primary1 py-6 sm:py-8 lg:py-12">
      <div className="mx-auto max-w-screen-2xl px-4 md:px-14 lg:px-20">
        <div className="flex flex-col items-center rounded-lg py-4 sm:py-8 lg:flex-row lg:justify-between lg:items-center">
          {/* Title Section */}
          <div className="mb-4 sm:mb-8 lg:mb-0 lg:flex-1">
            <h2 className="text-center text-xl font-bold font-museo sm:text-2xl lg:text-left lg:text-3xl text-white w-full lg:w-3/4">
              Join Our Newsletter to Keep Up To Date With Us!
            </h2>
          </div>

          {/* Input Section */}
          <div className="flex flex-col items-center w-full lg:w-auto lg:flex-1 lg:items-end">
            <form className="flex w-full max-w-md gap-2">
              <input
                placeholder="Enter your Email"
                className="bg-white w-full flex-1 rounded-lg px-3 py-3 text-gray-800 placeholder-gray-400 outline-none ring-indigo-300 transition duration-100 focus:ring"
              />
              <button className="inline-block font-museo rounded-lg btn-color px-4 sm:px-6 lg:px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-red-600 focus-visible:ring active:bg-red-700 md:text-base">
                Subscribe
              </button>
            </form>

            {/* Privacy Policy Section */}
            {/* <p className="mt-3 text-center text-xs text-gray-400 lg:text-right">
              By signing up to our newsletter you agree to our{" "}
              <a
                href="/"
                className="underline transition duration-100 hover:text-indigo-500 active:text-indigo-600"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="/"
                className="underline transition duration-100 hover:text-indigo-500 active:text-indigo-600"
              >
                Privacy Policy
              </a>
              .
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsLetter;
