import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";

const LabsEdit = () => {
  const dataTableRef = useRef();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [labs, setLabs] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedLabId, setSelectedLabId] = useState(null);
  const [editLab, setEditLab] = useState({
    name: "",
    house_no: "",
    street: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
  });

  // Fetch all labs on component mount
  useEffect(() => {
    const fetchLabs = async () => {
      try {
        const response = await axiosClient.get("labs");
        setLabs(response.data.labs); // Adjust according to your API response structure
        setLoading(false);

        if (dataTableRef.current) {
          $(dataTableRef.current).DataTable();
        }
      } catch (err) {
        setError("Failed to fetch labs. Please try again.");
        setLoading(false);
      }
    };

    fetchLabs();
  }, []);

  // Handle showing the edit modal with selected lab data
  const handleShowEdit = (lab) => {
    setSelectedLabId(lab?._id);
    setEditLab({
      name: lab?.name || "",
      house_no: lab?.house_no || "",
      street: lab?.street || "",
      landmark: lab?.landmark || "",
      city: lab?.city || "",
      state: lab?.state || "",
      pincode: lab?.pincode || "",
    });
    setShowEditModal(true);
  };

  // Close the edit modal
  const handleCloseEdit = () => {
    setShowEditModal(false);
    setError("");
  };

  // Update lab details
  const updateLab = async (e) => {
    e.preventDefault();
    try {
      await axiosClient.put(`labs/${selectedLabId}`, editLab);

      // Update the labs list with the edited lab
      setLabs(
        labs.map((lab) =>
          lab._id === selectedLabId ? { ...lab, ...editLab } : lab
        )
      );

      setShowEditModal(false);
    } catch (err) {
      setError("Failed to update lab. Please try again.");
    }
  };

  // Show the delete confirmation modal
  const handleShowDelete = (labId) => {
    setSelectedLabId(labId);
    setShowDeleteModal(true);
  };

  // Handle input changes in edit form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditLab((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Close the delete modal
  const handleCloseDelete = () => setShowDeleteModal(false);

  // Handle lab deletion
  const deleteLab = async () => {
    try {
      await axiosClient.delete(`labs/${selectedLabId}`);

      setLabs(labs.filter((lab) => lab._id !== selectedLabId));
      setShowDeleteModal(false);
    } catch (err) {
      setError("Failed to delete the lab. Please try again.");
      setShowDeleteModal(false);
    }
  };

  if (loading) return <p>Loading labs...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        {/* Delete confirmation modal */}
        <Modal show={showDeleteModal} onHide={handleCloseDelete} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Lab</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this lab?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleCloseDelete}>
              Cancel
            </button>
            <button className="modal-footer-btn" onClick={deleteLab}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        {/* Edit Lab Modal */}
        <Modal show={showEditModal} onHide={handleCloseEdit} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Lab</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={updateLab}>
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="mb-3">
                <label className="form-label">Lab Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={editLab.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">House No</label>
                <input
                  type="text"
                  className="form-control"
                  name="house_no"
                  value={editLab.house_no}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Street</label>
                <input
                  type="text"
                  className="form-control"
                  name="street"
                  value={editLab.street}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Landmark</label>
                <input
                  type="text"
                  className="form-control"
                  name="landmark"
                  value={editLab.landmark}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">City</label>
                <input
                  type="text"
                  className="form-control"
                  name="city"
                  value={editLab.city}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">State</label>
                <input
                  type="text"
                  className="form-control"
                  name="state"
                  value={editLab.state}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Pincode</label>
                <input
                  type="text"
                  className="form-control"
                  name="pincode"
                  value={editLab.pincode}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="modal-footer-btn"
                  onClick={handleCloseEdit}
                >
                  Cancel
                </button>
                <button type="submit" className="modal-footer-btn">
                  Save Changes
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <h5>Labs Details</h5>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>House No</th>
                      <th>Street</th>
                      <th>Landmark</th>
                      <th>City</th>
                      <th>State</th>
                      <th>Pincode</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {labs.map((lab, index) => (
                      <tr key={lab._id}>
                        <td>{index + 1}</td>
                        <td>{lab.name}</td>
                        <td>{lab.house_no}</td>
                        <td>{lab.street}</td>
                        <td>{lab.landmark}</td>
                        <td>{lab.city}</td>
                        <td>{lab.state}</td>
                        <td>{lab.pincode}</td>
                        <td>
                          <i
                            className="fa-solid fa-trash mr-2"
                            onClick={() => handleShowDelete(lab._id)}
                            style={{ cursor: "pointer", marginRight: "10px" }}
                          ></i>
                          <i
                            onClick={() => handleShowEdit(lab)}
                            className="fa-solid fa-pen-to-square"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LabsEdit;
