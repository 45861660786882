import React from "react";
import { FiArrowRight } from "react-icons/fi";

function BookTest() {
  return (
    <div className="md:py-20 py-12 px-4 sm:px-8 lg:px-20 max-w-screen-2xl mx-auto">
      <div className="relative bg-book md:rounded-xl rounded-lg pt-8 lg:pt-8 lg:pl-10 md:pl-6 pl-4 text-white flex flex-col md:flex-row items-center justify-between">
        {/* Left Side: Text Content */}
        <div className="lg:max-w-xl md:max-w-lg mr-2 md:mr-6">
          <h2 className="text-2xl lg:text-3xl font-bold mb-4 font-museo">
            Book your first Lab Test Now
          </h2>
          <p className="text-sm lg:text-base  mb-6 font-mont">
            Schedule your health checkup effortlessly online. Choose from a
            range of tests and book an appointment at your convenience, all from
            the comfort of your home. Your health is just a click away.
          </p>
          <button className="cursor-pointer bg-white font1 py-2 px-4 mb-10 font-museo text-sm md:text-base shadow-md flex items-center hover:bg-gray-100 transition">
            Book A Test Now
            <span className="ml-2">
              <FiArrowRight className="font1" />
            </span>
          </button>
        </div>

        {/* Right Side: Image */}
        <div className="mt-6 md:mt-0 md:flex-shrink-0">
          <img
            src="images/bookTest.png"
            alt="Lab Test"
            className="w-full lg:w-96 md:w-72 md:h-52 lg:h-56
            "
          />
        </div>
      </div>
    </div>
  );
}

export default BookTest;
