// import React, { useState } from "react";
// import Sidebar from "./Sidebar";
// import NewsLetter from "../landingPage/NewsLetter";
// import Footer from "../layout/Footer";
// import Navbar from "../layout/Navbar";
// import Navbar2 from "../layout/Navbar2";

// const users = [
//   { name: "John Doe" },
//   { name: "Jane Smith" },
//   { name: "Michael Lee" },
//   { name: "Emily Johnson" },
//   { name: "Chris Davis" },
//   { name: "Sara Wilson" },
//   // Add more items as needed
// ];

// function Prescriptions() {
//   // State to handle the current page
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 3;

//   // Calculate the current items to display
//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = users.slice(indexOfFirstItem, indexOfLastItem);

//   // Functions to navigate pages
//   const nextPage = () => {
//     if (currentPage < Math.ceil(users.length / itemsPerPage)) {
//       setCurrentPage(currentPage + 1);
//     }
//   };

//   const prevPage = () => {
//     if (currentPage > 1) {
//       setCurrentPage(currentPage - 1);
//     }
//   };

//   // Function to handle file uploads
//   const handleUpload = (e) => {
//     const file = e.target.files[0];
//     console.log("File uploaded:", file);
//   };
//   return (
//     <>
//       <Navbar />
//       <Navbar2 />
//       <div className="max-w-screen-2xl mx-auto md:py-14 lg:py-20 py-8 lg:px-20 md:px-12 px-4">
//         <div className="flex border-2 border-gray-300 shadow-lg rounded-3xl">
//           {/* Sidebar */}
//           <Sidebar />

//           {/* Main Content */}
//           <main className="w-3/4 p-8">
//             <div className="flex justify-between items-center">
//               <h1 className="text-2xl font-semibold font-museo font4 pb-5">
//                 Add Prescriptions
//               </h1>
//             </div>

//             <div className="mt-6">
//               {currentItems.map((user, index) => (
//                 <div
//                   key={index}
//                   className="bg-[#F8F8F8] p-4 mb-4 border-b border-b-red-600"
//                 >
//                   <h3 className="font4 font-museo font-semibold mb-4">
//                     {user.name}
//                   </h3>
//                   <div className="flex gap-4">
//                     <label className="border border-dashed p-4 w-48 text-center cursor-pointer font-museo font7">
//                       Upload Prescription
//                       <input
//                         type="file"
//                         className="hidden"
//                         onChange={handleUpload}
//                       />
//                     </label>
//                     <label className="border border-dashed p-4 w-48 text-center cursor-pointer font-museo font7">
//                       Upload Other Doc
//                       <input
//                         type="file"
//                         className="hidden"
//                         onChange={handleUpload}
//                       />
//                     </label>
//                   </div>
//                 </div>
//               ))}

//               {/* Pagination Buttons */}
//               <div className="flex justify-between mt-4">
//                 <button
//                   className="border-color2 font4 hover-color2 hover:text-white px-3 py-2 rounded-md font-museo disabled:opacity-50"
//                   onClick={prevPage}
//                   disabled={currentPage === 1}
//                 >
//                   Previous
//                 </button>
//                 <button
//                   className="border-color2 font4 hover-color2 hover:text-white px-6 py-2 rounded-md font-museo disabled:opacity-50"
//                   onClick={nextPage}
//                   disabled={
//                     currentPage === Math.ceil(users.length / itemsPerPage)
//                   }
//                 >
//                   Next
//                 </button>
//               </div>
//             </div>
//           </main>
//         </div>
//       </div>
//       <NewsLetter />
//       <Footer />
//     </>
//   );
// }

// export default Prescriptions;













import React, { useState } from "react";
import Sidebar from "./Sidebar";
import NewsLetter from "../landingPage/NewsLetter";
import Footer from "../layout/Footer";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";

const users = [
  { name: "John Doe" },
  { name: "Jane Smith" },
  { name: "Michael Lee" },
  { name: "Emily Johnson" },
  { name: "Chris Davis" },
  { name: "Sara Wilson" },
  // Add more items as needed
];

function Prescriptions() {
  // State to handle the current page
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  // Calculate the current items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = users.slice(indexOfFirstItem, indexOfLastItem);

  // Functions to navigate pages
  const nextPage = () => {
    if (currentPage < Math.ceil(users.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Function to handle file uploads
  const handleUpload = (e) => {
    const file = e.target.files[0];
    console.log("File uploaded:", file);
  };

  return (
    <>
      <Navbar />
      <Navbar2 />
      <div className="max-w-screen-2xl mx-auto md:py-14 lg:py-20 py-8 lg:px-20 md:px-12 px-4">
        <div className="flex border-2 border-gray-300 shadow-lg rounded-3xl">
          {/* Sidebar */}
          <Sidebar />

          {/* Main Content */}
          <main className="flex-1 p-4 md:p-8">
            <div className="flex justify-between items-center">
              <h1 className="text-2xl font-semibold font-museo font4 pb-5">
                Add Prescriptions
              </h1>
            </div>

            <div className="mt-6">
              {currentItems.map((user, index) => (
                <div
                  key={index}
                  className="bg-[#F8F8F8] p-4 mb-4 border-b border-b-red-600"
                >
                  <h3 className="font4 font-museo font-semibold mb-4">
                    {user.name}
                  </h3>
                  <div className="flex flex-col md:flex-row gap-4">
                    <label className="border border-dashed p-4 w-full md:w-48 text-center cursor-pointer font-museo font7">
                      Upload Prescription
                      <input
                        type="file"
                        className="hidden"
                        onChange={handleUpload}
                      />
                    </label>
                    <label className="border border-dashed p-4 w-full md:w-48 text-center cursor-pointer font-museo font7">
                      Upload Other Doc
                      <input
                        type="file"
                        className="hidden"
                        onChange={handleUpload}
                      />
                    </label>
                  </div>
                </div>
              ))}

              {/* Pagination Buttons */}
              <div className="flex justify-between mt-4">
                <button
                  className="border-color2 font4 hover-color2 hover:text-white px-3 py-2 rounded-md font-museo disabled:opacity-50"
                  onClick={prevPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <button
                  className="border-color2 font4 hover-color2 hover:text-white px-6 py-2 rounded-md font-museo disabled:opacity-50"
                  onClick={nextPage}
                  disabled={
                    currentPage === Math.ceil(users.length / itemsPerPage)
                  }
                >
                  Next
                </button>
              </div>
            </div>
          </main>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
}

export default Prescriptions;
