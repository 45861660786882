import React , {useEffect, useState} from "react";
import { Dialog } from "@headlessui/react";
import { RxCross2 } from "react-icons/rx";
import axiosClient from "../../axios-client";

const AddPatientsCart = ({ isEditing, isAdding, setIsEditing, setIsAdding, currentPatient, onRefresh}) => {
  const [currentPatientData, setCurrentPatientData] = useState(null);

  // Use useEffect to update currentPatientData whenever currentPatient changes
  useEffect(() => {
    setCurrentPatientData(currentPatient);
  }, [currentPatient]); // Dependency array includes currentPatient

  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setCurrentPatientData((prev) => ({
        ...prev,
        [name]: value,
      }));
    };
  
    const handleSave = async () => {
      try {
        const userId = localStorage.getItem("userId"); // Get userId from local storage
        if (!userId) {
          console.error('User ID is missing');
          return; // Exit if userId is not found
        }

         // Prepare data to send
        const dataToSend = {
          ...currentPatientData,
          userId, // Add userId to the data
        };

        if (isEditing) {
          // Edit patient API call
          const response = await axiosClient.put(`/users/family-member/${currentPatientData._id}`, dataToSend);
          console.log('Patient updated successfully:', response.data);
          setIsEditing(false);
        } else if (isAdding) {
          const response = await axiosClient.post(`/users/family-member`, dataToSend);
          setIsAdding(false);
        }
        onRefresh(); 
      } catch (error) {
        console.error('Error saving patient:', error);
      }
    };

    const handleDelete = async () => {
      const userId = localStorage.getItem("userId"); // Get userId from local storage
      if (userId && currentPatientData?._id) {
        try {
          // Delete patient API call
          const response = await axiosClient.delete(`/users/family-member/${currentPatientData._id}/${userId}`);
          console.log('Patient deleted successfully:', response.data);
          setIsEditing(false);
          setIsAdding(false);
          onRefresh(); 
        } catch (error) {
          console.error('Error deleting patient:', error);
        }
      } else {
        console.error('User ID or Patient ID is missing');
      }
    };
  

  return (
    <Dialog
      open={isEditing || isAdding}
      onClose={() => {
        setIsEditing(false);
        setIsAdding(false);
      }}
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-30 transition-opacity duration-300"
    >
      <Dialog.Panel className="bg-white rounded-lg shadow-md w-full max-w-lg">
        <Dialog.Title className="flex justify-between items-center text-xl font-bold mb-4 font-museo border-b-2 px-6 py-4">
          <span>{isEditing ? "Edit Patient" : "Add New Patient"}</span>
          <button
            onClick={() => {
              setIsEditing(false);
              setIsAdding(false);
            }}
            className="text-gray-500 hover:text-gray-700 ml-auto" // Added ml-auto to push button to the right
          >
            <RxCross2 className="h-6 w-6" aria-hidden="true" />
          </button>
        </Dialog.Title>

        <div className="grid grid-cols-2 gap-4 mx-6">
          <div className="col-span-2">
            <label
              className="block text-sm font-medium text-gray-700 font-mont"
              htmlFor="name"
            >
              Name
            </label>
            <input
              name="name"
              value={currentPatientData?.name || ""} // Use optional chaining
              onChange={handleInputChange}
              placeholder="Name"
              className="w-full col-span-2 p-2 border rounded-lg"
              required
            />
          </div>
          <div>
            <label
              className="block text-sm font-medium text-gray-700 font-mont"
              htmlFor="relation"
            >
              Relation
            </label>
            <input
              name="relation"
              value={currentPatientData?.relation || ""} // Use optional chaining
              onChange={handleInputChange}
              placeholder="Relation"
              className="w-full p-2 border rounded-lg"
            />
          </div>
          <div>
            <label
              className="block text-sm font-medium text-gray-700 font-mont"
              htmlFor="age"
            >
              Age
            </label>
            <input
              name="age"
              type="number" // Added type for number input
              value={currentPatientData?.age || ""} // Use optional chaining
              onChange={handleInputChange}
              placeholder="Age"
              className="w-full p-2 border rounded-lg"
              min="0"
              required
            />
          </div>
          <div>
            <label
              className="block text-sm font-medium text-gray-700 font-mont"
              htmlFor="dob"
            >
              Date of Birth
            </label>
            <input
              name="dob"
              type="date"
              value={currentPatientData?.dob || ""} // Use optional chaining
              onChange={handleInputChange}
              className="w-full p-2 border rounded-lg"
              required
            />
          </div>

          {/* Gender Input */}
          <div>
            <label
              className="block text-sm font-medium text-gray-700 font-mont"
              htmlFor="gender"
            >
              Gender
            </label>
            <select
              name="gender"
              value={currentPatientData?.gender || ""} // Use optional chaining
              onChange={handleInputChange}
              className="w-full p-2 border rounded-lg"
            >
              <option value="" disabled>
                Select Gender
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div>
            <label
              className="block text-sm font-medium text-gray-700 font-mont"
              htmlFor="phone"
            >
              Phone Number
            </label>
            <input
              name="phone"
              value={currentPatientData?.phone || ""} // Use optional chaining
              onChange={handleInputChange}
              placeholder="Phone"
              className="w-full p-2 border rounded-lg"
              pattern="[0-9]{10}"
              required
            />
          </div>
          {/* Email Input */}
          <div>
            <label
              className="block text-sm font-medium text-gray-700 font-mont"
              htmlFor="email"
            >
              Email
            </label>
            <input
              name="email"
              type="email" // Added type for email input
              value={currentPatientData?.email || ""} // Use optional chaining
              onChange={handleInputChange}
              placeholder="Email"
              required
              className="w-full p-2 border rounded-lg"
            />
          </div>
        </div>

        <div className="flex justify-between mt-5 border-t-2 pt-4 mb-4 font-mont">
          {isEditing && (
            <button
              onClick={handleDelete}
              className=" text-white px-4 py-2 rounded-md hover:bg-gray-400 font-mont ml-2 bg-red-600"
            >
              Delete Patient
            </button>
          )}
          <div className="ml-auto">
            <button
              onClick={handleSave}
              className="bg-card1 text-white px-4 py-2 rounded-md hover:bg-red-600 font-museo mx-2 font-mont"
            >
              {isEditing ? "Save Changes" : "Add New Patient"}
            </button>
          </div>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
}

export default AddPatientsCart;
