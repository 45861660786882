import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import NewsLetter from "../landingPage/NewsLetter";
import Footer from "../layout/Footer";

const MedicalSummary = () => {
  const [isEditable, setIsEditable] = useState(false);
  const [formData, setFormData] = useState({
    height: "170cm",
    weight: "60 Kg",
    hadCovid: "No",
    diabetes: "No",
    thyroid: "Yes",
    bloodPressure: "Normal",
    heartDisease: "No",
    pain: "No",
    otherRemarks: "No",
  });

  // Toggle edit mode
  const handleEdit = () => {
    setIsEditable(!isEditable);
  };

  // Handle form changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsEditable(false); // Disable edit mode after submitting
    console.log("Updated Data: ", formData);
  };

  return (
    <>
      <Navbar />
      <Navbar2 />
      <div className="max-w-screen-2xl mx-auto md:py-14 lg:py-20 py-8 lg:px-20 md:px-12 px-4 ">
        <div className="flex border-2 border-gray-300 shadow-lg rounded-3xl">
          {/* Sidebar */}
          <Sidebar />

          {/* Main Content */}
          <main className="w-3/4 p-8">
            <div className="flex justify-between items-center">
              <h1 className="text-2xl font-semibold font-museo font4 pb-5">
                Medical History
              </h1>
            </div>
            <div className="flex justify-end">
              <button
                onClick={handleEdit}
                className="text-blue-500 border border-blue-500 px-2 py-1 rounded"
              >
                {isEditable ? "Cancel" : "Edit"}
              </button>
            </div>

            {/* Conditionally Render Form */}
            {isEditable ? (
              <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
                {/* Editable Input Fields */}
                {Object.entries(formData).map(([key, value]) => (
                  <div key={key}>
                    <label className="block font-medium font7 font-mont capitalize">
                      {key.replace(/([A-Z])/g, " $1")}
                    </label>
                    <input
                      type="text"
                      name={key}
                      value={value}
                      onChange={handleChange}
                      className="w-full p-2 border-b border-red-600 bg-gray-100 focus:outline-none font-mont font7 font-bold"
                    />
                  </div>
                ))}
                <div className="col-span-2 w-1/5 mt-5">
                  <button
                    type="submit"
                    className="w-full bg-card1 text-white font-museo py-2 font-semibold rounded"
                  >
                    Update
                  </button>
                </div>
              </form>
            ) : (
              <div className="grid grid-cols-2 gap-4">
                {/* Non-editable Information Display */}
                {Object.entries(formData).map(([key, value]) => (
                  <div key={key}>
                    <label className="block font-medium font7 font-mont capitalize">
                      {key.replace(/([A-Z])/g, " $1")}
                    </label>
                    <p className=" font-mont font6 font-bold">{value}</p>
                  </div>
                ))}
              </div>
            )}
          </main>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
};

export default MedicalSummary;
