// import { useState } from "react";

// const DateTime = () => {
//   const [addressType, setAddressType] = useState("home");
//   const [homeLabel, setHomeLabel] = useState("Home");
//   const [addressFields, setAddressFields] = useState({
//     fullAddress: "",
//     houseNo: "",
//     street: "",
//     landmark: "",
//     city: "",
//     state: "",
//     pincode: "",
//   });
//   const [date, setDate] = useState("Enter Date");
//   const [timeSlot, setTimeSlot] = useState("");

//   const handleRadioChange = (event) => {
//     setAddressType(event.target.value);
//   };

//   const handleInputChange = (event) => {
//     setAddressFields({
//       ...addressFields,
//       [event.target.name]: event.target.value,
//     });
//   };

//   const handleDateChange = (event) => {
//     setDate(event.target.value);
//   };

//   const handleTimeSlotChange = (event) => {
//     setTimeSlot(event.target.value);
//   };

//   return (
//     <div>
//       <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 py-10">
//         {/* Selected Packages */}

//         <div className="bg-white border-2 border-gray-300 p-10 rounded-2xl shadow-md relative">
//           <div className="flex items-center mb-6 font-mont font-semibold text-xl">
//             <label className="mr-8">
//               <input
//                 type="radio"
//                 name="addressType"
//                 value="home"
//                 checked={addressType === "home"}
//                 onChange={handleRadioChange}
//                 className="mr-3 w-4 h-4 border border-red-500 rounded-full checked:bg-red-500 checked:border-red-500 focus:outline-red-600 cursor-pointer focus:outline-2 focus:outline-offset-2"
//               />
//               Home
//             </label>
//             <label>
//               <input
//                 type="radio"
//                 name="addressType"
//                 value="lab"
//                 checked={addressType === "lab"}
//                 onChange={handleRadioChange}
//                 className="mr-3 w-4 h-4 cursor-pointer"
//               />
//               Lab
//             </label>
//           </div>

//           {addressType === "lab" ? (
//             <div className="">
//               <h3 className="text-2xl font-bold mb-4 font-museo">
//                 Lab <span className="font1">Address</span>
//               </h3>
//               <div className="w-full py-4 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500 mt-5 font-mont font10 focus:ring-2 focus:ring-gray-200 focus:outline-none">
//                 Noida sector 16, floor 4, office 224/01, Kamla Street, Near Bal
//                 Bharti School
//               </div>

//               <h3 className="text-2xl font-bold pt-16 mb-6 font-museo">
//                 Add <span className="font1">Time</span>
//               </h3>
//               <input
//                 type="date"
//                 placeholder="Enter Date"
//                 className="w-full py-4 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500 my-3 font-museo font7 focus:ring-2 focus:ring-gray-200 focus:outline-none"
//                 value={date}
//                 onChange={handleDateChange}
//               />
//               <input
//                 type="text"
//                 placeholder="Select Time Slot"
//                 className="w-full py-4 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500 my-5 font-museo font7 focus:ring-2 focus:ring-gray-200 focus:outline-none"
//                 value={timeSlot}
//                 onChange={handleTimeSlotChange}
//               />
//               <div className="flex items-center w-full justify-end font-museo font-semibold text-lg absolute bottom-10 right-10">
//                 <button className="w-1/4 bg-card1 text-white py-3 rounded-lg">
//                   Submit
//                 </button>
//               </div>
//             </div>
//           ) : (
//             <div>
//               <h3 className="text-2xl font-bold mb-4 font-museo">
//                 Add <span className="font1">Address</span>
//               </h3>
//               <div className="">
//                 <label className="block mb-2 font-mont font-semibold">
//                   Save As
//                 </label>
//                 <select
//                   className="w-full p-2 mb-3 border border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 focus:outline-none font-mont"
//                   value={homeLabel}
//                   onChange={(e) => setHomeLabel(e.target.value)}
//                 >
//                   <option value="Home">Home</option>
//                   <option value="Office">Office</option>
//                   <option value="Others">Others</option>
//                 </select>
//               </div>
//               <div className="">
//                 <label className="block mb-2 font-mont font-semibold">
//                   Full Address
//                 </label>
//                 <input
//                   type="text"
//                   placeholder="Full Address"
//                   className="w-full p-2 mb-3 border border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 font-mont focus:outline-none"
//                   name="fullAddress"
//                   value={addressFields.fullAddress}
//                   onChange={handleInputChange}
//                 />
//               </div>

//               <div className="flex gap-3">
//                 <div className="w-full">
//                   <label className="block mb-2 font-mont font-semibold">
//                     House No.
//                   </label>
//                   <input
//                     type="text"
//                     placeholder="House No."
//                     className="w-full p-2 mb-3 font-mont border border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 focus:outline-none"
//                     name="houseNo"
//                     value={addressFields.houseNo}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//                 <div className="w-full">
//                   <label className="block mb-2 font-mont font-semibold">
//                     Street
//                   </label>
//                   <input
//                     type="text"
//                     placeholder="Street"
//                     className="w-full p-2 mb-3 border font-mont border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 focus:outline-none"
//                     name="street"
//                     value={addressFields.street}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//               </div>
//               <div className="flex gap-3">
//                 <div className="w-full">
//                   <label className="block mb-2 font-mont font-semibold">
//                     Landmark
//                   </label>
//                   <input
//                     type="text"
//                     placeholder="Landmark"
//                     className="w-full p-2 mb-3 font-mont border border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 focus:outline-none"
//                     name="landmark"
//                     value={addressFields.landmark}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//                 <div className="w-full">
//                   <label className="block mb-2 font-mont font-semibold">
//                     City
//                   </label>
//                   <input
//                     type="text"
//                     placeholder="City"
//                     className="w-full p-2 mb-3 font-mont border border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 focus:outline-none"
//                     name="city"
//                     value={addressFields.city}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//               </div>
//               <div className="flex gap-3">
//                 <div className="w-full">
//                   <label className="block mb-2 font-mont font-semibold">
//                     State
//                   </label>
//                   <input
//                     type="text"
//                     placeholder="State"
//                     className="w-full p-2 mb-3 font-mont border border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 focus:outline-none"
//                     name="state"
//                     value={addressFields.state}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//                 <div className="w-full">
//                   <label className="block mb-2 font-mont font-semibold">
//                     Pincode
//                   </label>
//                   <input
//                     type="text"
//                     placeholder="Pincode"
//                     className="w-full p-2 mb-3 font-mont border border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 focus:outline-none"
//                     name="pincode"
//                     value={addressFields.pincode}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//               </div>

//               <h3 className="text-2xl font-bold pt-10 mb-4 font-museo">
//                 Add <span className="font1">Time</span>
//               </h3>
//               <input
//                 type="date"
//                 placeholder="Enter Date"
//                 className="w-full py-4 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500 my-3 font-museo font7 focus:ring-2 focus:ring-gray-200 focus:outline-none"
//                 value={date}
//                 onChange={handleDateChange}
//               />
//               <input
//                 type="text"
//                 placeholder="Select Time Slot"
//                 className="w-full py-4 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500 my-5 font-museo font7 focus:ring-2 focus:ring-gray-200 focus:outline-none"
//                 value={timeSlot}
//                 onChange={handleTimeSlotChange}
//               />
//               <div className="mt-20">
//                 <div className="flex items-center w-full justify-start font-euclid font-medium text-lg absolute  bottom-10 left-10 cursor-pointer">
//                   <button className="w-1/4 font6 border-color1 py-3 rounded-lg cursor-pointer hover-color1 hover:text-white transition-all duration-300">
//                     Add New Address
//                   </button>
//                 </div>
//                 <div className="flex items-center w-full justify-end font-museo font-semibold text-lg absolute bottom-10 right-10">
//                   <button className="w-1/4 bg-card1 text-white py-3 rounded-lg">
//                     Submit
//                   </button>
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>

//         {/* Offers Section */}
//         <div className="bg-white p-10 rounded-2xl shadow-md border-2 border-gray-300">
//           <h3 className="text-2xl font-bold mb-4 font-museo">
//             Check <span className="font1">Offers</span>
//           </h3>

//           {/* Display Offers */}
//           <div className="space-y-8">
//             {[1, 2].map((offer) => (
//               <div
//                 key={offer}
//                 className="band-gradient flex items-center justify-center rounded-md p-2"
//               >
//                 <div className="text-white font-museo font-semibold pl-3">
//                   <h5>Home Sample Collection Available</h5>
//                 </div>
//                 <div className="flex items-center justify-end">
//                   <img src="others/nurse.png" alt="df" width={"27%"} />
//                 </div>
//               </div>
//             ))}
//             <div className="py-3 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500">
//               <div className="font-museo text-lg flex items-center justify-between border-d mt-1">
//                 <p className="font7">Total Amount</p>
//                 <p className="font7">₹1099</p>
//               </div>
//               <div className="font-museo text-lg flex items-center justify-between border-d mt-1">
//                 <p className="font7">Extra Charge </p>
//                 <p className="font7">₹400</p>
//               </div>
//               <div className="font-museo text-lg flex items-center justify-between mt-1">
//                 <p className="font6">Amount to be paid </p>
//                 <p className="font6">₹1599</p>
//               </div>
//             </div>
//           </div>

//           {/* Continue Button */}
//           <div className="flex items-center justify-center mt-20 mb-2 font-museo font-semibold text-lg">
//             <button className="w-3/4 mt-6 bg-card1 text-white py-3 rounded-lg">
//               Continue To Next Step
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default DateTime;

import { useState } from "react";
import { Dialog } from "@headlessui/react";
import AddAddress from "./AddAddressCart"; // Import the AddAddress component

const DateTime = () => {
  const [addressType, setAddressType] = useState("home");
  const [homeLabel, setHomeLabel] = useState("Home");
  const [addressFields, setAddressFields] = useState({
    fullAddress: "",
    houseNo: "",
    street: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
  });
  const [date, setDate] = useState("Enter Date");
  const [timeSlot, setTimeSlot] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [isEditing, setIsEditing] = useState(false); // State to track editing mode

  const handleRadioChange = (event) => {
    setAddressType(event.target.value);
  };

  const handleInputChange = (event) => {
    setAddressFields({
      ...addressFields,
      [event.target.name]: event.target.value,
    });
  };

  const handleDateChange = (event) => {
    setDate(event.target.value);
  };

  const handleTimeSlotChange = (event) => {
    setTimeSlot(event.target.value);
  };

  const handleOpenModal = () => {
    console.log("Opening modal");
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    console.log("Closing modal");
    setIsModalOpen(false);
    // Optionally reset address fields
    setAddressFields({
      fullAddress: "",
      houseNo: "",
      street: "",
      landmark: "",
      city: "",
      state: "",
      pincode: "",
    });
  };
  const handleSaveAddress = () => {
    // Logic to save the address
    console.log("Address saved:", addressFields);
    handleCloseModal();
  };
  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 py-10">
        {/* Selected Packages */}

        <div className="bg-white border-2 border-gray-300 p-10 rounded-2xl shadow-md relative">
          <div className="flex items-center mb-6 font-mont font-semibold text-xl">
            <label className="mr-8">
              <input
                type="radio"
                name="addressType"
                value="home"
                checked={addressType === "home"}
                onChange={handleRadioChange}
                className="mr-3 w-4 h-4 border border-red-500 rounded-full checked:bg-red-500 checked:border-red-500 focus:outline-red-600 cursor-pointer focus:outline-2 focus:outline-offset-2"
              />
              Home
            </label>
            <label>
              <input
                type="radio"
                name="addressType"
                value="lab"
                checked={addressType === "lab"}
                onChange={handleRadioChange}
                className="mr-3 w-4 h-4 cursor-pointer"
              />
              Lab
            </label>
          </div>

          {addressType === "lab" ? (
            <div className="">
              <h3 className="text-2xl font-bold mb-4 font-museo">
                Lab <span className="font1">Address</span>
              </h3>
              <div className="w-full py-4 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500 mt-5 font-mont font10 focus:ring-2 focus:ring-gray-200 focus:outline-none">
                Noida sector 16, floor 4, office 224/01, Kamla Street, Near Bal
                Bharti School
              </div>

              <h3 className="text-2xl font-bold pt-16 mb-6 font-museo">
                Add <span className="font1">Time</span>
              </h3>
              <input
                type="date"
                placeholder="Enter Date"
                className="w-full py-4 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500 my-3 font-museo font7 focus:ring-2 focus:ring-gray-200 focus:outline-none"
                value={date}
                onChange={handleDateChange}
              />
              <input
                type="text"
                placeholder="Select Time Slot"
                className="w-full py-4 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500 my-5 font-museo font7 focus:ring-2 focus:ring-gray-200 focus:outline-none"
                value={timeSlot}
                onChange={handleTimeSlotChange}
              />
              <div className="flex items-center w-full justify-end font-museo font-semibold text-lg absolute bottom-10 right-10">
                <button className="w-1/4 bg-card1 text-white py-3 rounded-lg">
                  Submit
                </button>
              </div>
            </div>
          ) : (
            <div>
              <h3 className="text-2xl font-bold mb-4 font-museo">
                Add <span className="font1">Address</span>
              </h3>
              <div className="">
                <label className="block mb-2 font-mont font-semibold">
                  Save As
                </label>
                <select
                  className="w-full p-2 mb-3 border border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 focus:outline-none font-mont"
                  value={homeLabel}
                  onChange={(e) => setHomeLabel(e.target.value)}
                >
                  <option value="Home">Home</option>
                  <option value="Office">Office</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div className="">
                <label className="block mb-2 font-mont font-semibold">
                  Full Address
                </label>
                <input
                  type="text"
                  placeholder="Full Address"
                  className="w-full p-2 mb-3 border border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 font-mont focus:outline-none"
                  name="fullAddress"
                  value={addressFields.fullAddress}
                  onChange={handleInputChange}
                />
              </div>

              <div className="flex gap-3">
                <div className="w-full">
                  <label className="block mb-2 font-mont font-semibold">
                    House No.
                  </label>
                  <input
                    type="text"
                    placeholder="House No."
                    className="w-full p-2 mb-3 font-mont border border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 focus:outline-none"
                    name="houseNo"
                    value={addressFields.houseNo}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="w-full">
                  <label className="block mb-2 font-mont font-semibold">
                    Street
                  </label>
                  <input
                    type="text"
                    placeholder="Street"
                    className="w-full p-2 mb-3 border font-mont border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 focus:outline-none"
                    name="street"
                    value={addressFields.street}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex gap-3">
                <div className="w-full">
                  <label className="block mb-2 font-mont font-semibold">
                    Landmark
                  </label>
                  <input
                    type="text"
                    placeholder="Landmark"
                    className="w-full p-2 mb-3 font-mont border border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 focus:outline-none"
                    name="landmark"
                    value={addressFields.landmark}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="w-full">
                  <label className="block mb-2 font-mont font-semibold">
                    City
                  </label>
                  <input
                    type="text"
                    placeholder="City"
                    className="w-full p-2 mb-3 font-mont border border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 focus:outline-none"
                    name="city"
                    value={addressFields.city}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="flex gap-3">
                <div className="w-full">
                  <label className="block mb-2 font-mont font-semibold">
                    State
                  </label>
                  <input
                    type="text"
                    placeholder="State"
                    className="w-full p-2 mb-3 font-mont border border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 focus:outline-none"
                    name="state"
                    value={addressFields.state}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="w-full">
                  <label className="block mb-2 font-mont font-semibold">
                    Pincode
                  </label>
                  <input
                    type="text"
                    placeholder="Pincode"
                    className="w-full p-2 mb-3 font-mont border border-[#d9d9d9] rounded-lg focus:ring-2 focus:ring-gray-200 focus:outline-none"
                    name="pincode"
                    value={addressFields.pincode}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <h3 className="text-2xl font-bold pt-10 mb-4 font-museo">
                Add <span className="font1">Time</span>
              </h3>
              <input
                type="date"
                placeholder="Enter Date"
                className="w-full py-4 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500 my-3 font-museo font7 focus:ring-2 focus:ring-gray-200 focus:outline-none"
                value={date}
                onChange={handleDateChange}
              />
              <input
                type="text"
                placeholder="Select Time Slot"
                className="w-full py-4 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500 my-5 font-museo font7 focus:ring-2 focus:ring-gray-200 focus:outline-none"
                value={timeSlot}
                onChange={handleTimeSlotChange}
              />
              <div className="mt-20">
                <div className="flex items-center w-full justify-start font-euclid font-medium text-lg absolute  bottom-10 left-10 cursor-pointer">
                  <button
                    onClick={handleOpenModal}
                    className="w-2/5 font6 border-color1 py-3 rounded-lg cursor-pointer hover-color1 hover:text-white transition-all duration-300"
                  >
                    Add New Address
                  </button>
                </div>
                <div className="flex items-center w-full justify-end font-museo font-semibold text-lg absolute bottom-10 right-10">
                  <button className="w-1/4 bg-card1 text-white py-3 rounded-lg">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Offers Section */}
        <div className="bg-white p-10 rounded-2xl shadow-md border-2 border-gray-300">
          <h3 className="text-2xl font-bold mb-4 font-museo">
            Check <span className="font1">Offers</span>
          </h3>

          {/* Display Offers */}
          <div className="space-y-8">
            {[1, 2].map((offer) => (
              <div
                key={offer}
                className="band-gradient flex items-center justify-center rounded-md p-2"
              >
                <div className="text-white font-museo font-semibold pl-3">
                  <h5>Home Sample Collection Available</h5>
                </div>
                <div className="flex items-center justify-end">
                  <img src="others/nurse.png" alt="df" width={"27%"} />
                </div>
              </div>
            ))}
            <div className="py-3 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500">
              <div className="font-museo text-lg flex items-center justify-between border-d mt-1">
                <p className="font7">Total Amount</p>
                <p className="font7">₹1099</p>
              </div>
              <div className="font-museo text-lg flex items-center justify-between border-d mt-1">
                <p className="font7">Extra Charge </p>
                <p className="font7">₹400</p>
              </div>
              <div className="font-museo text-lg flex items-center justify-between mt-1">
                <p className="font6">Amount to be paid </p>
                <p className="font6">₹1599</p>
              </div>
            </div>
          </div>

          {/* Continue Button */}
          <div className="flex items-center justify-center mt-20 mb-2 font-museo font-semibold text-lg">
            <button className="w-3/4 mt-6 bg-card1 text-white py-3 rounded-lg">
              Continue To Next Step
            </button>
          </div>
        </div>
      </div>
      {/* AddAddress Modal */}
      <AddAddress
        isEditing={isEditing}
        isAdding={isModalOpen}
        setIsEditing={setIsEditing}
        setIsAdding={setIsModalOpen}
        currentAddress={addressFields}
        handleInputChange={handleInputChange}
        handleSave={handleSaveAddress}
      />
    </div>
  );
};

export default DateTime;
