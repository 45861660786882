import React from "react";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import Footer from "../layout/Footer";
import NewsLetter from "../landingPage/NewsLetter";
import { Link } from "react-router-dom";
function Congratulation() {
  return (
    <>
      <Navbar />
      <Navbar2 />

      {/* Main container with spacing */}
      <div className="flex items-center justify-center bg-white mt-20 mb-20">
        <div className="w-[990px] h-[600px] bg-[#CEE0FF] rounded-lg shadow-lg flex flex-col items-center justify-center">
          {/* Tick Image */}
          <div className="flex items-center justify-center">
            <img
              src="./images/maximising-user-satisfaction-1-removebg-preview 1.png"
              alt="Success"
              className="w-60 h-60 -mt-16"  // Reduced the margin-top
            />
          </div>

          {/* Text Content */}
          <div className="text-center mt-2">
            <h1 className="text-4xl font-bold text-[#2E3192] font-mont">
              Congratulations !!
            </h1>
            <p className="text-[#636060] mt-4 font-museo font-semibold">Your payment was successful</p>
          </div>

          {/* Button */}
          <Link to="/">
    <button className="mt-20 bg-[#DF4132] text-white py-2 px-12 rounded-lg hover:bg-[#e04e4e] focus:outline-none focus:ring-2 focus:ring-[#FF5F5F] font-poppins">
      Go to Dashboard
    </button>
  </Link>
        </div>
      </div>

      <NewsLetter />
      <Footer />
    </>
  );
}

export default Congratulation;
