import React from "react";
import Sidebar from "./Sidebar";
import NewsLetter from "../landingPage/NewsLetter";
import Footer from "../layout/Footer";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import BookingsAndReports from "./Bookings";

function BookingReports() {
  return (
    <>
      <Navbar />
      <Navbar2 />
      <div className="max-w-screen-2xl mx-auto md:py-14 lg:py-20 py-8 lg:px-20 md:px-12 px-4">
        <div className="flex border-2 border-gray-300 shadow-lg rounded-3xl">
          {/* Sidebar */}
          <Sidebar />

          {/* Main Content */}
          <main className="w-3/4 p-8">
            <div className="flex justify-between items-center">
              <h1 className="lg:text-2xl md:text-xl text-lg font-semibold font-museo font4 mr-2">
                Bookings and Reports
              </h1>
            </div>

            <BookingsAndReports />
          </main>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
}

export default BookingReports;
