import React, { useState } from "react";
import DateTime from "./DateTime";
import AddPatients from "./AddPatients";
import Payment from "./Payment";
import CheckTests from "./CheckTests";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import Footer from "../layout/Footer";
import NewsLetter from "../landingPage/NewsLetter";

const MultiStepForm = () => {
  const [step, setStep] = useState(1);

  const nextStep = () => {
    if (step < 4) setStep(step + 1);
  };

  const prevStep = () => {
    if (step > 1) setStep(step - 1);
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return <CheckTests />;
      case 2:
        return <AddPatients />;
      case 3:
        return <DateTime />;
      case 4:
        return <Payment />;
      default:
        return null;
    }
  };

  return (
    <>
      <Navbar />
      <Navbar2 />
      <div className="max-w-screen-2xl mx-auto md:py-14 lg:py-20 py-8 lg:px-20 md:px-12 px-4 flex flex-col items-center justify-center">
        {/* Step Indicator */}
        <div className="w-full max-w-4xl mb-8">
          <div className="flex items-center justify-between w-full relative">
            {steps.map((item, index) => (
              <div key={index} className="flex items-center justify-center">
                <div className=" flex flex-col items-center text-center">
                  <div
                    className={`rounded-full w-14 h-14 flex items-center justify-center font-semibold font-museo z-20 
                    ${
                      step === index + 1
                        ? "bg-card1 text-white"
                        : "border-2 border-color1 font6 bg-white"
                    }
                  `}
                  >
                    {index + 1}
                  </div>
                  <span className="mt-2 font7 font-museo font-medium">
                    {item.label}
                  </span>
                </div>

                {/* {index < steps.length - 1 && (
              )} */}
              </div>
            ))}
            <div className="absolute top-6 left-5 sm:w-[550px] md:w-[600px] lg:w-[820px] w-[85%]  h-1 bg-blue-800 z-10"></div>
          </div>
        </div>

        {/* Step Content */}
        <div className="w-full max-w-screen-2xl py-6">
          <div>{renderStepContent()}</div>
          <div className="mt-8 flex justify-between">
            {step > 1 && (
              <button
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                onClick={prevStep}
              >
                Previous
              </button>
            )}
            {step < 4 && (
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 ml-auto"
                onClick={nextStep}
              >
                Next
              </button>
            )}
            {step === 4 && (
              <button className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 ml-auto">
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
};

const steps = [
  { label: "Check Tests" },
  { label: "Add Patients" },
  { label: "Date & Time" },
  { label: "Payment" },
];

export default MultiStepForm;
