import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import BarChart from './login/BarChart';
import PieChartComp from './login/PieChartComp';


const Main = () => {
  const [showPopup, setShowPopup] = useState(true);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const adminToken = localStorage.getItem("admintoken");
    if (!adminToken) {
      navigate("/admin/login"); // Redirect if no adminToken
    }
  }, [navigate]);

  return (
    <>
      <SideBar />
      <Navbar />

      <div className="home">
        <div class="toggle-sidebar" style={{ display: "none" }}>
          <i class="bi bi-menu"></i>
        </div>
        <div className="admin-banner">
          <div className="row">
            {showPopup && (
              <div className="col-lg-12 dash-up">
                <div className="dash-strip">
                  <div>
                    <h3>Congrats!! you can now modify your application</h3>
                    <p>
                      Keep all the information up to date with minimal effort
                    </p>
                  </div>
                  <div>
                    <i
                      className="bi bi-x"
                      onClick={() => setShowPopup(false)}
                    ></i>
                  </div>
                </div>
                <div className="over"></div>
              </div>
            )}
            <div className="col-lg-4">
              <div className="admin-dashbox">
                <h2>
                  <i className="bi bi-person"></i> Total Registered Users :
                </h2>
                {/* <h3 className="pt-2">10</h3> */}
                <ul className="overlapping-avatar">
                  <li>
                    <img src="/images/user1.png" alt="" />
                  </li>
                  <li>
                    <img src="/images/user2.png" alt="" />
                  </li>
                  <li>
                    <img src="/images/user3.png" alt="" />
                  </li>
                  <li>
                    <span>100+</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="admin-dashbox">
                <h2>
                  <i className="bi bi-bookmark"></i> Total Test Performed :{" "}
                </h2>
                <div className="dash-space">
                  <div>
                    <h3 className="pt-2">500</h3>
                    <p>
                      50% <i className="bi bi-arrow-up"></i> from last year
                    </p>
                  </div>
                  <div className="dash-img">
                    <img src="/images/graph.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="admin-dashbox">
                <h2>
                  <i className="bi bi-bookmark"></i> Completed Orders :{" "}
                </h2>
                <div className="dash-space">
                  <div>
                    <h3 className="pt-2">500</h3>
                    <p>
                      50% <i className="bi bi-arrow-up"></i> from last year
                    </p>
                  </div>
                  <div className="dash-img">
                    <img src="/images/graph.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
        
            <div className="col-lg-12 p-4">
              <div className="row dash-recent">
                <div className="col-lg-12">
                  <div className="recents">
                    <h5>Additional Information</h5>
                    <p>
                    An admin panel for a lab test website typically serves as the back-end management interface, allowing administrators to manage various aspects of the lab’s operations. Below are the common features and functionalities that are often part of such an admin panel:
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
