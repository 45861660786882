import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosClient from "../../axios-client";
import Navbar2 from "../layout/Navbar2";
import Navbar from "../layout/Navbar";
import NewsLetter from "../landingPage/NewsLetter";
import Footer from "../layout/Footer";

const Register = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [successMessage, setSuccessMessage] = useState("");

  // Handle number-only input
  const handleMobileChange = (e) => {
    const value = e.target.value;
    // Regular expression to allow only numbers
    if (/^\d*$/.test(value)) {
      setMobileNumber(value);
      setError(""); // clear error if valid
    } else {
      setError("Please enter only numeric values");
    }
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    if (!mobileNumber) {
      setError("Mobile number is required");
    } else if (mobileNumber.length < 10) {
      setError("Mobile number must be at least 10 digits");
    } else {
      try {
        // Make API call to send OTP
        const response = await axiosClient.post("users/login", {
          phone: mobileNumber,
        });
        setSuccessMessage(response.data.message);
        navigate("/otp", { state: { mobileNumber } });
      } catch (error) {
        if (error.response) {
          setError(error.response.data.message || "Failed to send OTP");
        } else {
          setError("An error occurred. Please try again.");
        }
      }
    }
  };

  return (
    <div>
      <Navbar />
      <Navbar2 />
      <div className="bg-[#2E319233] w-full flex">
        {/* Left side image */}
        <div className="hidden md:flex items-center justify-center p-20 ">
          <img
            src="images/loginPage.png"
            alt="Register Illustration"
            className=" md:w-[600px] img-block pt-10"
          />
        </div>

        {/* Right side form */}
        <div className="w-full lg:w-1/2 px-4 py-24 md:p-16 lg:py-32 lg:px-20  md:shadow-2xl md:rounded-l-3xl bg-white">
          <h2 className="lg:text-4xl text-2xl font1 font-museo font-bold text-center">
            Login/Register
          </h2>
          <p className="text-center lg:text-lg text-sm font7 lg:my-5 md:my-4 my-2 italic font-mont ">
            Please enter your Mobile No
          </p>
          <div className="flex flex-col items-center justify-center w-full">
            <form
              onSubmit={handleSendOtp}
              className="lg:space-y-4 space-y-2 w-5/6 font-mont"
            >
              <div>
                <label
                  htmlFor="mobileNumber"
                  className="block lg:text-lg font-medium font-mont lg:mb-3 mb-2 pt-3"
                >
                  Enter Mobile No.
                </label>
                <input
                  type="tel"
                  id="mobileNumber"
                  value={mobileNumber}
                  onChange={handleMobileChange}
                  className="w-full px-3 lg:py-3 py-2 border font9 border-color3  rounded-lg focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter Registered Mobile no."
                  pattern="[0-9]*" // Allows only numeric input in browsers supporting pattern
                  required
                />
                {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
              </div>

              <div className="lg:pt-7 pt-5">
                <button
                  type="submit"
                  className="w-full bg-card1 text-white font-semibold lg:text-lg text-base lg:py-3 py-2 rounded-lg hover-color1 transition duration-200 font-museo"
                >
                  Send OTP
                </button>
              </div>
              {/* <div className="text-center font-mont font-medium">
                <p className="lg:text-base text-xs">
                  Already have an account?{" "}
                  <a href="/login" className="font1 hover:underline">
                    Login
                  </a>
                </p>
              </div> */}
            </form>
          </div>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </div>
  );
};

export default Register;
