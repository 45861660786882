import React, { useState } from "react";

import { Link } from "react-router-dom";
import Modal1 from "./PreviewReport";

const CompletedTests = () => {
  const reports = [
    { id: 1, name: "Full Body Test", date: "10/08/2024", price: 1500 },
    { id: 2, name: "Blood Sugar Test", date: "12/08/2024", price: 800 },
    { id: 3, name: "Cholesterol Test", date: "15/08/2024", price: 600 },
    { id: 4, name: "Liver Function Test", date: "20/08/2024", price: 1000 },
    { id: 5, name: "Kidney Function Test", date: "22/08/2024", price: 900 },
  ];

  const [currentPage, setCurrentPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const reportsPerPage = 2;

  const handleDownload = (reportId) => {
    const fileUrl = `/path-to-your-report-file-${reportId}.pdf`;
    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = `Report-${reportId}.pdf`;
    a.click();
  };

  const handleShare = (reportId) => {
    if (navigator.share) {
      navigator
        .share({
          title: `${reports[reportId - 1].name} Report`,
          text: `Here is the report of your ${
            reports[reportId - 1].name
          } completed on ${reports[reportId - 1].date}.`,
          url: window.location.href,
        })
        .then(() => console.log("Report shared successfully!"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      alert("Your browser does not support the Web Share API");
    }
  };

  const displayedReports = reports.slice(
    currentPage * reportsPerPage,
    currentPage * reportsPerPage + reportsPerPage
  );

  const handleNextPage = () => {
    if ((currentPage + 1) * reportsPerPage < reports.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePreview = (report) => {
    setSelectedReport(report);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedReport(null);
  };

  return (
    <div className="p-2">
      {displayedReports.map((report) => (
        <TestCard
          key={report.id}
          report={report}
          handleDownload={() => handleDownload(report.id)}
          handleShare={() => handleShare(report.id)}
          handlePreview={() => handlePreview(report)} // Pass handlePreview
        />
      ))}
      <div className="flex justify-between mt-6">
        <button
          className={`border-color2 font4 hover-color2 hover:text-white px-4 py-2 rounded-md font-museo ${
            currentPage === 0 ? "opacity-50 cursor-not-allowed" : ""
          }`}
          onClick={handlePreviousPage}
          disabled={currentPage === 0}
        >
          Previous
        </button>
        <button
          className={`border-color2 font4 hover-color2 hover:text-white px-6 py-2 rounded-md font-museo ${
            (currentPage + 1) * reportsPerPage >= reports.length
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
          onClick={handleNextPage}
          disabled={(currentPage + 1) * reportsPerPage >= reports.length}
        >
          Next
        </button>
      </div>

      {/* Modal to preview report */}
      <Modal1
        isOpen={isModalOpen}
        onClose={closeModal}
        report={selectedReport}
      />
    </div>
  );
};

const TestCard = ({ report, handleDownload, handleShare, handlePreview }) => (
  <div className="bg-white p-6 rounded-md shadow-lg mb-4 flex flex-col">
    <div className="flex justify-between mb-4">
      <h3 className="text-lg font-semibold font-museo">{report.name}</h3>
      <p className="text-lg font-semibold font-museo">₹ {report.price}</p>
    </div>

    <div className="flex justify-between mb-4 font-mont">
      <p className="font7">The report completed on {report.date}</p>
      <Link to="#" className="font1 underline" onClick={handlePreview}>
        Preview
      </Link>
    </div>

    <div className="flex justify-between font-museo font-semibold">
      <button
        className="bg-card1 hover-color1 text-white px-4 py-2 rounded-lg"
        onClick={handleDownload}
      >
        Download Report
      </button>
      <button
        className="bg-card1 hover-color1 text-white px-4 py-2 rounded-lg"
        onClick={handleShare}
      >
        Share Report
      </button>
    </div>
  </div>
);

export default CompletedTests;
