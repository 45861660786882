// import React, { useEffect, useState } from "react";
// import { FiLogOut } from "react-icons/fi";
// import { MdOutlineKeyboardArrowRight } from "react-icons/md";
// import { Link, useLocation } from "react-router-dom";
// import iziToast from "izitoast";
// import axiosClient from "../../axios-client";

// const Sidebar = () => {
//   const [userInfo, setUserInfo] = useState({
//     firstName: "",
//     lastName: "",
//     phone: "",
//   });

//   const userId = localStorage.getItem("userId");

//   useEffect(() => {
//     const fetchUserData = async () => {
//       try {
//         const response = await axiosClient.get(`users/details/${userId}`);
//         const userData = response?.data?.user;
//         setUserInfo(userData);
//       } catch (error) {
//         console.error("Error fetching user data:", error);
//       }
//     };

//     fetchUserData();
//   }, [userId]);

//   const handleLogout = async () => {
//     localStorage.removeItem("userId");
//     localStorage.removeItem("token");
//     await axiosClient.get("users/logout");

//     iziToast.success({
//       message: "Logout successful",
//       position: "topCenter",
//     });
//     window.location.href = "/";
//   };

//   return (
//     <aside className="w-auto md:w-1/4 md:h-screen p-2 md:p-6 border-r border-gray-200 overflow-hidden relative">
//       {/* Profile Info */}
//       <Link to={"/profile"}>
//         <div className="flex items-center space-x-4 mb-8">
//           <div className="w-12 h-12 bg-red-500 p-3 rounded-full flex justify-center items-center text-white">
//             <img src="images/profile.svg" alt="Profile" />
//           </div>
//           <div>
//             <h2 className="font-semibold font-museo font1">
//               {userInfo?.firstName} {userInfo?.lastName}
//             </h2>
//             <p className="text-xs font-mont font7">{userInfo?.phone}</p>
//           </div>
//           <div className="ml-8">
//             <MdOutlineKeyboardArrowRight className="ml-20" />
//           </div>
//         </div>
//       </Link>

//       {/* Divider */}
//       <div className="border-t mb-4"></div>

//       {/* Navigation Links */}
//       <nav>
//         <SidebarItem
//           to="/familymembers"
//           icon={<img src="images/mdi_family.png" alt="Family Members" />}
//           text="Family Members"
//         />
//         <div className="border-t mb-4"></div>
//         <SidebarItem
//           to="/prescription"
//           icon={<img src="images/ion_medical.png" alt="Prescription" />}
//           text="Prescription"
//         />
//         <div className="border-t mb-4"></div>
//         <SidebarItem
//           to="/addresses"
//           icon={<img src="images/carbon_map.png" alt="Addresses" />}
//           text="Addresses"
//         />
//         <div className="border-t mb-4"></div>
//         <SidebarItem
//           to="/bookings-reports"
//           icon={
//             <img src="images/ion_list-outline.png" alt="Bookings and Reports" />
//           }
//           text="Bookings and Reports"
//         />
//         <div className="border-t mb-4"></div>
//         <SidebarItem
//           to="/medical-summary"
//           icon={<img src="images/apple.png" alt="Medical Summary" />}
//           text="Medical Summary"
//         />
//         <div className="border-t mb-4"></div>
//         <SidebarItem
//           to="/payments"
//           icon={<img src="images/ion_list-outline.png" alt="Payments" />}
//           text="Payments"
//         />
//       </nav>

//       {/* Divider */}
//       <div className="border-t mb-4"></div>

//       {/* Logout */}
//       <button
//         onClick={handleLogout}
//         className="flex items-center space-x-3 text-red-500 hover:text-red-600 absolute bottom-9"
//       >
//         <FiLogOut size={20} />
//         <span>Logout</span>
//       </button>
//       <div className="border-t mt-36"></div>
//     </aside>
//   );
// };

// const SidebarItem = ({ icon, text, to }) => {
//   const location = useLocation(); // Get current location

//   // Check if the current path matches the 'to' prop
//   const isActive = location.pathname === to;

//   return (
//     <Link
//       to={to}
//       className={`flex flex-col items-center justify-center py-2 md:py-1 font1 font-bold font-museo rounded-lg hover:font-semibold ${
//         isActive ? "bg-blue-800 text-white" : "hover-color3 hover:text-white"
//       }`}
//     >
//       <span className="w-8 h-8">{icon}</span>
//       <span className="text-center hidden md:block">{text}</span> {/* Center text only on larger screens */}
//     </Link>
//   );
// };

// export default Sidebar;

import React, { useEffect, useState } from "react";
import { FiLogOut } from "react-icons/fi";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import iziToast from "izitoast";
import axiosClient from "../../axios-client";

const Sidebar = () => {
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    phone: "",
  });

  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosClient.get(`users/details/${userId}`);
        const userData = response?.data?.user;
        setUserInfo(userData);
      } catch (error) {
        console.error("Error fetching user data:", error);
        iziToast.error({
          message: "Failed to load user data.",
          position: "topCenter",
        });
      }
    };

    fetchUserData();
  }, [userId]);

  const handleLogout = async () => {
    try {
      await axiosClient.get("users/logout");
      localStorage.removeItem("userId");
      localStorage.removeItem("token");
      iziToast.success({
        message: "Logout successful",
        position: "topCenter",
      });
      window.location.href = "/";
    } catch (error) {
      console.error("Logout failed:", error);
      iziToast.error({
        message: "Logout failed. Please try again.",
        position: "topCenter",
      });
    }
  };

  return (
    <aside className="flex flex-col h-screen w-16 md:w-1/4 p-4 md:p-6 border-r border-gray-200 transition-width duration-300">
      {/* Profile Info */}
      <Link to="/profile">
        <div className="flex items-center space-x-2 mb-6">
          <div className="w-12 h-12 bg-red-500 p-2 rounded-full flex justify-center items-center text-white">
            <img src="images/profile.svg" alt="Profile" />
          </div>
          <div className="hidden md:block">
            <h2 className="font-semibold text-base md:text-lg font-museo">
              {userInfo?.firstName} {userInfo?.lastName}
            </h2>
            <p className="text-xs md:text-sm font-mont">{userInfo?.phone}</p>
          </div>
          <MdOutlineKeyboardArrowRight className="hidden md:block ml-4" />
        </div>
      </Link>

      {/* Divider */}
      <div className="border-t mb-4"></div>

      {/* Navigation Links */}
      <nav className="flex-1 flex flex-col space-y-2">
        {[
          { to: "/familymembers", icon: "images/mdi_family.png", text: "Family Members" },
          { to: "/prescription", icon: "images/ion_medical.png", text: "Prescription" },
          { to: "/addresses", icon: "images/carbon_map.png", text: "Addresses" },
          { to: "/bookings-reports", icon: "images/ion_list-outline.png", text: "Bookings and Reports" },
          { to: "/medical-summary", icon: "images/apple.png", text: "Medical Summary" },
          { to: "/payments", icon: "images/ion_list-outline.png", text: "Payments" },
        ].map((item, index) => (
          <React.Fragment key={index}>
            <SidebarItem
              to={item.to}
              icon={<img src={item.icon} alt={item.text} className="w-8 h-8" />}
              text={item.text}
            />
            {index < 6 && (
              <div className="border-b border-blue-500 my-1"></div>
            )}
          </React.Fragment>
        ))}
      </nav>

      {/* Divider */}
      <div className="border-t mb-4"></div>

      <div className="mt-auto">
        <button
          onClick={handleLogout}
          className="flex items-center space-x-3 text-red-500 hover:text-red-600"
        >
          <FiLogOut size={20} />
          <span className="hidden md:block">Logout</span>
        </button>
      </div>
    </aside>
  );
};

const SidebarItem = ({ icon, text, to }) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Link
      to={to}
      className={`flex items-center space-x-2 py-2 font1 font-bold font-museo rounded-lg hover:font-semibold ${
        isActive ? "bg-blue-800 text-white" : "hover-color3 hover:text-white"
      }`}
    >
      {icon}
      <span className="flex-1 text-left hidden md:block">{text}</span> {/* Hide text on small screens */}
    </Link>
  );
};

export default Sidebar;

