import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";

const UsersEdit = () => {
  const dataTableRef = useRef();
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [editUser, setEditUser] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    dob: "",
    gender: "",
  });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axiosClient.get("users/details");

        setUsers(response.data.user);
        setLoading(false);

        if (dataTableRef.current) {
          $(dataTableRef.current).DataTable();
        }
      } catch (err) {
        setError("Failed to fetch users. Please try again.");
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleShowEdit = (user) => {
    setSelectedUserId(user?._id);
    setEditUser({
      firstName: user?.firstName,
      lastName: user?.lastName,
      phone: user?.phone,
      email: user?.email,
      dob: user?.dob ? (isNaN(new Date(user?.dob)) ? '' : new Date(user?.dob).toISOString().split('T')[0]) : '',
      gender: user?.gender,
    });
    setShowEdit(true);
  };

  const handleCloseEdit = () => {
    setShowEdit(false);
    setError("");
  };

  const updateUser = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosClient.put(
        `users/details/${selectedUserId}`,
        editUser
      );

      // Update the users list with the edited user
      setUsers(
        users?.map((user) =>
          user?._id === selectedUserId ? { ...user, ...editUser } : user
        )
      );

      setShowEdit(false);
    } catch (err) {
      setError("Failed to update user. Please try again.");
    }
  };

  // Show the delete confirmation modal
  const handleShow = (userId) => {
    setSelectedUserId(userId);
    setShow(true);
  };

  // Handle input changes in edit form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClose = () => setShow(false);

  // Handle user deletion
  const deleteUser = async () => {
    try {
      await axiosClient.delete(`users/details/${selectedUserId}`);

      setUsers(users.filter((user) => user?._id !== selectedUserId));
      setShow(false);
    } catch (err) {
      setError("Failed to delete the user. Please try again.");
      setShow(false);
    }
  };

  if (loading) return <p>Loading users...</p>;
  if (error) return <p>{error}</p>;

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        {/* Delete confirmation modal */}
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this user?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleClose}>
              Cancel
            </button>
            <button className="modal-footer-btn" onClick={deleteUser}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        {/* Edit User Modal */}
        <Modal show={showEdit} onHide={handleCloseEdit} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={updateUser}>
              {error && <div className="alert alert-danger">{error}</div>}
              <div className="mb-3">
                <label className="form-label">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="firstName"
                  value={editUser?.firstName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="lastName"
                  value={editUser?.lastName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Phone</label>
                <input
                  type="tel"
                  className="form-control"
                  name="phone"
                  value={editUser?.phone}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={editUser?.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">DOB</label>
                <input
                  type="date"
                  className="form-control"
                  name="dob"
                  value={editUser?.dob}
                  onChange={handleInputChange}
                  max={new Date().toISOString().split("T")[0]}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Gender</label>
                <select
                  className="form-control"
                  name="gender"
                  value={editUser?.gender}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="others">Others</option>
                </select>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="modal-footer-btn"
                  onClick={handleCloseEdit}
                >
                  Cancel
                </button>
                <button type="submit" className="modal-footer-btn">
                  Save Changes
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <h5>User Details</h5>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Joined On</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.map((user, index) => (
                      <tr key={user?._id}>
                        <td>{index + 1}</td>
                        <td>{user?.firstName}</td>
                        <td>{user?.lastName}</td>
                        <td>{user?.phone}</td>
                        <td>{user?.email}</td>
                        <td>{new Date(user?.createdAt).toLocaleDateString()}</td>
                        <td>
                          <i
                            className="fa-solid fa-trash mr-2"
                            onClick={() => handleShow(user?._id)}
                          ></i>
                          <i
                            onClick={() => handleShowEdit(user)}
                            className="fa-solid fa-pen-to-square"
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UsersEdit;
