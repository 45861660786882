import React from "react";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";

import NewsLetter from "../landingPage/NewsLetter";
import Footer from "../layout/Footer";

import Banner from "./PrivacyBanner";

function PrivacyPolicy() {
  return (
    <>
      <Navbar />
      <Navbar2 />
      <Banner />
      <div className="md:py-20 py-12 px-4 sm:px-8 lg:px-20 mx-auto max-w-screen-2xl font-mont">
        <h1 className="md:text-3xl text-2xl font-bold mb-6">Privacy Policy</h1>

        <p
          className="md:pb-6 pb-4"
          style={{ lineHeight: "2", textAlign: "justify" }}
        >
          At Dr. Palve Hospital, accessible from{" "}
          <a href="https://labs.drpalvehospital.com/" className="text-blue-500">
            https://labs.drpalvehospital.com
          </a>
          , your privacy is our priority. This Privacy Policy explains how we
          collect, use, and safeguard your personal information. By using our
          services, you agree to the terms of this Privacy Policy.
        </p>

        <h2 className="md:text-3xl text-2xl font-bold mb-6">
          Detailed Privacy Policy
        </h2>

        <h3 className="md:text-xl text-lg font-semibold mb-4">
          1. Information We Collect
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-1"
            style={{ lineHeight: "1", textAlign: "justify" }}
          >
            We collect various types of information, including:
          </p>
          <p
            className="md:mb-2  font-semibold"
            style={{ lineHeight: "1", textAlign: "justify" }}
          >
            Personal Information:
          </p>
          <ul className="list-disc pl-6">
            <li className="mt-1">
              <span className="font-semibold">Identity Information: </span>
              <span>Name, date of birth, gender, and contact information.</span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">Health Information: </span>
              <span>
                Medical history, diagnostic reports, health concerns, and
                treatment data.
              </span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">Transaction Information: </span>
              <span>
                Billing details, payment methods, and records of services
                provided.
              </span>
            </li>
          </ul>
          <p
            className="mb-2 mt-4 font-semibold"
            style={{ lineHeight: "1", textAlign: "justify" }}
          >
            Non-Personal Information:
          </p>
          <ul className="list-disc pl-6 mb-6">
            <li className="mt-1">
              <span className="font-semibold">Log Data: </span>
              <span>
                Information such as your IP address, browser type, operating
                system, and the pages you visit.
              </span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">Cookies: </span>
              <span>
                Cookies help us enhance the user experience by storing
                preferences and recognizing returning visitors.
              </span>
            </li>
          </ul>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-4">
          2. How We Use Your Information
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "1", textAlign: "justify" }}
          >
            We use your information for a variety of purposes, including:
          </p>
          <ul className="list-disc pl-6">
            <li className="mt-1">
              <span className="font-semibold">Service Delivery: </span>
              <span>
                To schedule and provide diagnostic services, maintain medical
                records, and facilitate communication between doctors and
                patients.
              </span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">Payment Processing: </span>
              <span>
                For billing and payment purposes when you book diagnostic
                services.
              </span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">Personalization: </span>
              <span>
                Tailoring your experience on our website and improving our
                services by understanding user behavior.
              </span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">Legal Compliance: </span>
              <span>
                To comply with applicable laws and regulations regarding
                healthcare and data privacy.
              </span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">Communication: </span>
              <span>
                To send you information, updates, and alerts related to your
                appointments, services, and relevant health information.
              </span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">Marketing and Promotions: </span>
              <span>
                Occasionally, we may send promotional offers related to
                healthcare services that might be of interest to you.
              </span>
            </li>
          </ul>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-2 mt-5">
          3. Cookies Policy
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "2", textAlign: "justify" }}
          >
            We use cookies to enhance user experience. Cookies help us recognize
            you, track website traffic, and personalize content. You can control
            or disable cookies in your browser settings, but this may limit the
            use of certain features on our website.
          </p>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-4">
          4. How We Protect Your Data
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "1", textAlign: "justify" }}
          >
            We adopt stringent security measures to protect your data,
            including:
          </p>
          <ul className="list-disc pl-6 mb-6">
            <li className="mt-1">
              <span className="font-semibold">Encryption: </span>
              <span>
                Sensitive data, such as health records and payment information,
                are encrypted.
              </span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">Access Controls: </span>
              <span>
                Access to personal information is restricted to authorized
                personnel only.
              </span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">Regular Audits: </span>
              <span>
                We periodically review our security practices to ensure that
                your data remains protected.
              </span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">Third-Party Security: </span>
              <span>
                Any third-party service providers involved in processing your
                data are obligated to follow the same security standards.
              </span>
            </li>
          </ul>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-4">
          5. Sharing of Information
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "1", textAlign: "justify" }}
          >
            We may share your personal data with:
          </p>
          <ul className="list-disc pl-6 mb-6">
            <li className="mt-1">
              <span className="font-semibold">Healthcare Providers: </span>
              <span>
                Doctors and medical professionals involved in your care.
              </span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">Payment Processors: </span>
              <span>To facilitate payments for services rendered.</span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">
                Government and Regulatory Bodies:{" "}
              </span>
              <span>
                As required by law for public health, safety, and regulatory
                compliance.
              </span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">
                Third-Party Service Providers:{" "}
              </span>
              <span>
                Companies that assist in the delivery of our services, including
                customer support, payment processing, or IT infrastructure.
              </span>
            </li>
          </ul>
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "1", textAlign: "justify" }}
          >
            We do not sell or rent your personal information to third parties
            for marketing purposes.
          </p>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-4 mt-4">
          6. Third-Party Links
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "2", textAlign: "justify" }}
          >
            Our website may contain links to third-party websites. We are not
            responsible for the privacy practices or the content of such
            external sites. Please review their respective privacy policies.
          </p>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-4">
          7. Your Rights Regarding Your Data
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "1", textAlign: "justify" }}
          >
            You have rights regarding the personal information we hold about
            you, including:
          </p>
          <ul className="list-disc pl-6 mb-6">
            <li className="mt-1">
              <span className="font-semibold">Right to Access: </span>
              <span>
                You can request access to your personal information and obtain a
                copy of the data we hold.
              </span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">Right to Rectification: </span>
              <span>
                If any information is inaccurate or incomplete, you can request
                a correction.
              </span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">
                Right to Erasure (Right to be Forgotten):{" "}
              </span>
              <span>
                Under certain circumstances, you can request the deletion of
                your personal data.
              </span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">
                Right to Restriction of Processing:{" "}
              </span>
              <span>
                You may request that we restrict the processing of your personal
                data in certain conditions.
              </span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">Right to Data Portability: </span>
              <span>
                You can request that we transfer your data to another provider.
              </span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">Right to Object: </span>
              <span>
                You can object to our processing of your personal data for
                certain purposes, such as direct marketing.
              </span>
            </li>
          </ul>
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "2", textAlign: "justify" }}
          >
            If you wish to exercise any of these rights, please contact us at{" "}
            <a
              href="mailto:support@drpalvehospital.com"
              className="text-blue-500"
            >
              support@drpalvehospital.com
            </a>
            . We will respond to your request within a reasonable timeframe, not
            exceeding 30 Days.
          </p>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-4 mt-4">
          8. Data Retention
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "2", textAlign: "justify" }}
          >
            We retain your personal data only for as long as necessary to
            fulfill the purposes outlined in this Privacy Policy, or as required
            by law. Medical records are typically retained in accordance with
            healthcare regulations and industry standards.
          </p>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-4 mt-4">
          9. Changes to This Privacy Policy
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "2", textAlign: "justify" }}
          >
            We reserve the right to update this Privacy Policy from time to time
            to reflect changes in our practices or for other operational, legal,
            or regulatory reasons. All updates will be posted on this page with
            the new effective date. We encourage you to periodically review this
            page for the latest information on our privacy practices.
          </p>
        </div>

        <h3 className="md:text-xl text-lg font-semibold mb-4">
          10. Contact Information
        </h3>
        <div className="ml-6">
          <p
            className="md:mb-4 mb-2"
            style={{ lineHeight: "1", textAlign: "justify" }}
          >
            If you have any questions or concerns regarding this Privacy Policy,
            or if you would like to file a complaint, please contact us at:
          </p>
          <ul className="list-disc pl-6 mb-6">
            <li className="mt-1">
              <span className="font-semibold">Email: </span>
              <span>
                <a
                  href="mailto:support@drpalvehospital.com"
                  className="text-blue-500"
                >
                  support@drpalvehospital.com
                </a>
              </span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">Phone: </span>
              <span>081492 02506</span>
            </li>
            <li className="mt-1">
              <span className="font-semibold">Address: </span>
              <span>
                Dr Palve Multispecialty Hospital, Chandan Nagar, Kharadi, Pune,
                Maharashtra - 411014.
              </span>
            </li>
          </ul>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
