import React from "react";

const SortModal = ({
  sortBy,
  setSortBy,
  resetSort,
  closeModal
}) => (
  <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
    <div className="bg-white md:w-[40%] w-56 rounded shadow-lg relative">
      <button
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 w-5"
        onClick={closeModal}
      >
        &times;
      </button>

      <h2 className="text-xl font-semibold mb-4 border-b-2 font-museo py-4 px-4">
        Sort By
      </h2>

      <div className="mb-4 px-6 font-museo md:text-base text-sm">
        <div className="flex flex-col space-y-2">
          {[
            { value: "popularity", label: "Popularity" },
            { value: "price-low", label: "Price: Low to High" },
            { value: "price-high", label: "Price: High to Low" },
            { value: "recent", label: "Most Recent" }
          ].map(option => (
            <label key={option.value} className="flex items-center space-x-2">
              <input
                type="radio"
                name="sortBy"
                value={option.value}
                checked={sortBy === option.value}
                onChange={(e) => setSortBy(e.target.value)}
                className="form-radio"
              />
              <span>{option.label}</span>
            </label>
          ))}
        </div>
      </div>

      <div className="flex justify-between mt-6 border-t-2 py-4 px-6 font-museo md:text-base text-sm">
        <button
          onClick={resetSort}
          className="bg-secondary1 text-white md:px-4 md:py-2 px-2 py-1 rounded-lg"
        >
          Reset
        </button>
        <button
          onClick={closeModal}
          className="bg-card1 text-white md:px-4 md:py-2 px-2 py-1 rounded-lg"
        >
          Apply
        </button>
      </div>
    </div>
  </div>
);

export default React.memo(SortModal);
