import React, { useState, useEffect } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import { useNavigate } from "react-router-dom";


const AddTestParameter = () => {
  const [currentEntry, setCurrentEntry] = useState({
    title: "",
    parameters: [],
  });

  const [parameterInput, setParameterInput] = useState("");
  const [tests, setTests] = useState([]); 
  const [testId, setTestId] = useState(""); 
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTests = async () => {
      try {
        const response = await axiosClient.get("tests/tests");
        setTests(response.data.test);
      
      } catch (err) {
        console.error(err);
        setError("Failed to fetch tests. Please try again.");
     
      }
    };

    fetchTests();
  }, []);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentEntry((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddParameter = (e) => {
    e.preventDefault();
    if (parameterInput.trim()) {
      setCurrentEntry((prev) => ({
        ...prev,
        parameters: [...prev.parameters, parameterInput.trim()],
      }));
      setParameterInput(""); 
    }
  };

  const handleRemoveParameter = (index) => {
    setCurrentEntry((prev) => ({
      ...prev,
      parameters: prev.parameters.filter((_, i) => i !== index), 
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const count = currentEntry.parameters.length; 
    const dataToSubmit = {
      ...currentEntry,
      testId,
      count, 
    };

    try {

      const response = await axiosClient.post("tests-parameters/test/parameter/add", dataToSubmit);
      setCurrentEntry({ title: "", parameters: [] });
      setTestId("");
      setTimeout(() => {
        navigate("/admin/all-test-parameter");
      }, 1000);
    } catch (error) {
      console.error("Error saving data: ", error);
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container pt-3">
          <div className="row top-barcolumn">
            <h5>Add Test Parameter</h5>
            <div className="row nav-column">
              <div>
                <form className="row" onSubmit={handleSubmit}>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Test </label>
                    <select
                      className="form-control"
                      value={testId}
                      onChange={(e) => setTestId(e.target.value)} 
                      required
                    >
                      <option value="">Select Test</option>
                      {tests.map((test) => (
                        <option key={test._id} value={test._id}>
                          {test.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label htmlFor="title" className="form-label">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="title"
                      placeholder="Test Title"
                      value={currentEntry.title}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label htmlFor="parameter" className="form-label">
                      Add Parameter
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        id="parameter"
                        placeholder="Add Test Parameter"
                        value={parameterInput}
                        onChange={(e) => setParameterInput(e.target.value)}
                      />
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleAddParameter}
                      >
                        Add
                      </button>
                    </div>
                  </div>

                  <div className="col-lg-12 mb-3">
                    <h6>Parameters:</h6>
                    <ul className="list-group">
                      {currentEntry.parameters.map((parameter, index) => (
                        <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                          {parameter}
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handleRemoveParameter(index)}
                          >
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="">
                    <button type="submit" className="btn btn-success">
                      Save Test Parameters
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddTestParameter;
