import React, { useState, useEffect } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import { useNavigate, useParams } from "react-router-dom";

const EditTestParameter = () => {
  const { id } = useParams(); // Get the id from URL
  const [currentEntry, setCurrentEntry] = useState({
    title: "",
    parameters: [],
  });

  const [parameterInput, setParameterInput] = useState("");
  const [tests, setTests] = useState([]);
  const [testId, setTestId] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTests = async () => {
      try {
        const response = await axiosClient.get("tests/tests");
        setTests(response.data.test);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch tests. Please try again.");
      }
    };

    const fetchTestParameter = async () => {
      try {
        const response = await axiosClient.get(`tests-parameters/test/parameter/${id}`);
        const { title, parameters, testId } = response.data; // Adjust based on your response structure
        setCurrentEntry({ title, parameters });
        setTestId(testId);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch test parameter. Please try again.");
      }
    };

    fetchTests();
    fetchTestParameter(); // Fetch the test parameter details
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentEntry((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddParameter = (e) => {
    e.preventDefault();
    if (parameterInput.trim()) {
      setCurrentEntry((prev) => ({
        ...prev,
        parameters: [...prev.parameters, parameterInput.trim()],
      }));
      setParameterInput("");
    }
  };

  const handleRemoveParameter = (index) => {
    setCurrentEntry((prev) => ({
      ...prev,
      parameters: prev.parameters.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const count = currentEntry.parameters.length;
    const dataToSubmit = {
      ...currentEntry,
      testId,
      count,
    };

    try {
      await axiosClient.put(`tests-parameters/test/parameter/${id}`, dataToSubmit); // Change to PUT
      setCurrentEntry({ title: "", parameters: [] });
      setTestId("");
      navigate("/admin/all-test-parameter");
    } catch (error) {
      console.error("Error updating data: ", error);
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />
      <section className="home">
        <div className="container pt-3">
          <div className="row top-barcolumn">
            <h5>Edit Test Parameter</h5>
            <form className="row" onSubmit={handleSubmit}>
              <div className="col-lg-6 mb-3">
                <label className="form-label">Test </label>
                <select
                  className="form-control"
                  value={testId}
                  onChange={(e) => setTestId(e.target.value)}
                  required
                >
                  <option value="">Select Test</option>
                  {tests.map((test) => (
                    <option key={test._id} value={test._id}>
                      {test.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-6 mb-3">
                <label htmlFor="title" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  name="title"
                  placeholder="Test Title"
                  value={currentEntry.title}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="col-lg-6 mb-3">
                <label htmlFor="parameter" className="form-label">
                  Add Parameter
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="parameter"
                    placeholder="Add Test Parameter"
                    value={parameterInput}
                    onChange={(e) => setParameterInput(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleAddParameter}
                  >
                    Add
                  </button>
                </div>
              </div>

              <div className="col-lg-6 mb-3">
                <h6>Parameters:</h6>
                <ul className="list-group">
                  {currentEntry.parameters.map((parameter, index) => (
                    <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                      {parameter}
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => handleRemoveParameter(index)}
                      >
                        Remove
                      </button>
                    </li>
                  ))}
                </ul>
              </div>

              <div>
                <button type="submit" className="btn btn-success">
                  Update Test Parameters
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditTestParameter;
