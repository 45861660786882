import { useEffect, useState } from "react";
import { useAuth } from "../../AuthContext";
import axiosClient from "../../axios-client";
import { Link } from "react-router-dom";

const CheckTests = () => {
  const { cart, removeFromCart } = useAuth(); // Get the cart array from useAuth
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [packages, setPackages] = useState([]); // State to manage additional packages
  const [step, setStep] = useState(1); // Step state for navigation


  // Function to fetch test details based on IDs in the cart
  const fetchTests = async () => {
    try {
      const promises = cart.map((testId) =>
        axiosClient.get(`/tests/test/${testId}`)
      );

      // Wait for all promises to resolve
      const results = await Promise.all(promises);
      // Extract the test data from the responses
      const fetchedTests = results.map((result) => result.data.test);
      setTests(fetchedTests);
    } catch (err) {
      setError("Failed to fetch tests.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (cart.length > 0) {
      fetchTests(); // Fetch tests when the component mounts or when cart changes
    }
  }, [cart]);

  // If loading, show loading message
  if (loading) return <div>Loading...</div>;

  // If there was an error, show error message
  if (error) return <div>{error}</div>;

  // Function to go to the next step
  const nextStep = () => {
    if (step < 4) setStep(step + 1);
  };

  // Function to add more packages (up to 3 max visible)
  const addPackage = () => {
    if (packages.length < 3) {
      const newPackageId = packages.length > 0 ? packages[packages.length - 1].id + 1 : 1; // Increment ID
      const newPackage = {
        id: newPackageId,
        name: `New Package ${newPackageId}`,
        price: 1299,
      };
      setPackages([...packages, newPackage]);
    } else {
      alert("You can only add up to 3 packages.");
    }
  };

  // // Function to remove a package by its ID
  // const removePackage = (id) => {
  //   setPackages(packages.filter((pkg) => pkg.id !== id));
  // };

  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 py-10">
        {/* Selected Packages */}
        <div className="bg-white border-2 border-gray-300 p-10 rounded-2xl shadow-md">
          <h3 className="text-2xl font-bold mb-4 font-museo">
            Selected <span className="font1">Packages</span>
          </h3>

          {/* Display package items dynamically */}
          <div className="space-y-4">
            {tests.map((item) => (
              <div
                key={item._id}
                className="flex justify-between items-center py-3 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500"
              >
                <div>
                  <h4 className="font-semibold font-museo font4 text-base">
                    {item.title} - {item.subTitle}
                  </h4>
                  <p className="font6 font-medium mt-2 font-museo">
                    ₹{item.price}
                  </p>
                </div>
                <button
                  className="bg-card1 text-white px-4 py-2 rounded-md font-museo font-semibold"
                  onClick={() => removeFromCart(item._id)} // Removes the test from packages
                >
                  Remove
                </button>
              </div>
            ))}
          </div>

          {/* Add More Tests Button */}
          <Link to={"/testBrowse"}>
          <div className="flex items-center justify-center mt-4 font-museo font-semibold text-lg">
          
            <button
              className="w-3/4 mt-6 bg-card1 text-white py-3 rounded-lg"
              onClick={addPackage}
            >
              + Add More Tests
            </button>
          
          </div>
          </Link>
        </div>

        {/* Offers Section */}
        <div className="bg-white p-10 rounded-2xl shadow-md border-2 border-gray-300">
          <h3 className="text-2xl font-bold mb-4 font-museo">
            Check <span className="font1">Offers</span>
          </h3>

          {/* Display Offers */}
          <div className="space-y-8">
            {[1, 2, 3].map((offer) => (
              <div
                key={offer}
                className="band-gradient flex items-center justify-center rounded-md p-2"
              >
                <div className="text-white font-museo font-semibold pl-3">
                  <h5>Home Sample Collection Available</h5>
                </div>
                <div className="flex items-center justify-end">
                  <img src="others/nurse.png" alt="df" width={"27%"} />
                </div>
              </div>
            ))}
          </div>

          {/* Continue Button */}
          <div className="flex items-center justify-center mt-9 mb-2 font-museo font-semibold text-lg">
            <button
              onClick={nextStep}
              className="w-3/4 mt-6 bg-card1 text-white py-3 rounded-lg"
            >
              Continue To Next Step
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckTests;
