import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom"; 
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";

const EditTestCategory = () => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [totalTest, setTotalTest] = useState(""); 
  const [testImage, setTestImage] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
  
    const fetchCategory = async () => {
      try {
        const response = await axiosClient.get(`test-categories/test/category/${id}`);
        setName(response.data.testCategory.name);
        setTotalTest(response.data.testCategory.TotalTest); // Set TotalTest
        setTestImage(response.data.testCategory.TestImage); // Set TestImage
      } catch (err) {
        setError("Failed to fetch test category details.");
      }
    };

    fetchCategory();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("TotalTest", totalTest);
    if (testImage) {
      formData.append("TestImage", testImage); // Append the image file
    }

    try {
      await axiosClient.put(`test-categories/test/category/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set content type for file upload
        },
      });

      setSuccess("Test category updated successfully!");
      setError("");
      navigate("/admin/test-category-details");
    } catch (err) {
      setError("Failed to update test category.");
      setSuccess("");
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container pt-3">
          <div className="row top-barcolumn">
            <h5>Edit Test Category</h5>

            {/* Display success or error messages */}
            {error && <div className="alert alert-danger">{error}</div>}
            {success && <div className="alert alert-success">{success}</div>}

            <div className="row nav-column">
              <div>
                <form className="row" onSubmit={handleSubmit}>
                  <div className="col-lg-6 mb-3">
                    <label htmlFor="nav-items1" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nav-item1"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Name"
                    />
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label htmlFor="totalTest" className="form-label">
                      Total Test
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="totalTest"
                      value={totalTest}
                      onChange={(e) => setTotalTest(e.target.value)}
                      placeholder="Total Tests"
                      required 
                    />
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label htmlFor="testImage" className="form-label">
                      Test Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="testImage"
                      onChange={(e) => setTestImage(e.target.files[0])} // Get the selected file
                    />
                    {testImage && <p>{testImage.name}</p>} {/* Display the name of the selected file */}
                  </div>

                  <div className="">
                    <button type="submit" className="btn btn-success">
                      Update Test Category
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditTestCategory;
