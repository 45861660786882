import React, { useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";
import { useNavigate } from "react-router-dom";

const AddTestCategory = () => {

  const [name, setName] = useState("");
  const [totalTest, setTotalTest] = useState(""); 
  const [testImage, setTestImage] = useState(null); 
  const [error, setError] = useState(""); 
  const [success, setSuccess] = useState(""); 
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("TotalTest", totalTest);
    if (testImage) {
      formData.append("TestImage", testImage); // Append the image file
    }

    try {
      const response = await axiosClient.post("test-categories/test/category/add", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set content type for file upload
        },
      });

      setSuccess(response.data.message || "Test category added successfully!");
      setName("");
      setTotalTest("");
      setTestImage(null);
      setError(""); 

    
      setTimeout(() => {
        navigate("/admin/test-category-details"); 
      }, 1000); 
    } catch (err) {
      setError(err.response ? err.response.data.message : "Failed to add test category.");
      setSuccess("");
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div class="container pt-3">
          <div class="row top-barcolumn">
            <h5>Add New Test Category</h5>
            {error && <div className="alert alert-danger">{error}</div>} {/* Display error message */}
            {success && <div className="alert alert-success">{success}</div>} {/* Display success message */}
            <div class="row nav-column">
              <div>
                <form className="row" onSubmit={handleSubmit}>
                <div className="col-lg-6 mb-3">
                    <label htmlFor="nav-items1" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nav-item1"
                      value={name} 
                      onChange={(e) => setName(e.target.value)} 
                      placeholder="Name"
                      required 
                    />
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label htmlFor="totalTest" className="form-label">
                      Total Test
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="totalTest"
                      value={totalTest}
                      onChange={(e) => setTotalTest(e.target.value)}
                      placeholder="Total Tests"
                      required 
                    />
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label htmlFor="testImage" className="form-label">
                      Test Image
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="testImage"
                      onChange={(e) => setTestImage(e.target.files[0])} // Get the selected file
                      required 
                    />
                  </div>
                 
                  <div className="">
                    <button type="submit" className="btn btn-success">
                      Add Test Category
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AddTestCategory