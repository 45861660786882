// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { useStateContext } from "../../../contexts/AuthContextProvider";
// import axiosClient from "../../../axios-client";

// const Login = () => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [showPassword, setShowPassword] = useState(false);
//   const [error, setError] = useState("");
//   const navigate = useNavigate();
//   const { setToken, setUser } = useStateContext();

//   const handleVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     console.log("Full :");

//     try {
//       const response = await axiosClient.post("/admin/login", {
//         email,
//         password,
//       });

//       if (response.data.success) {
//         setUser(response.data.user);
//         setToken(response.data.token);
//         navigate("/admin/dashboard");
//       } else {
//         setError("Login failed. Please try again.");
//       }
//     } catch (err) {
//       if (err.response && err.response.data) {
//         setError(err.response.data.message || "Login failed.");
//       } else {
//         setError("An unexpected error occurred.");
//       }
//     }
//   };

//   return (
//     <>
//       <div className="container-fluid login">
//         <div className="row login-row">
//           <div className="col-lg-6">
//             <div className="img-login">
//               <img src="/images/Mobile login-rafiki.png" alt="" width={"120"} />
//             </div>
//           </div>
//           <div className="col-lg-6 login-right">
//             <h1 className="color">Admin Login</h1>
//             <p>Welcome!</p>
//             {error && <div className="alert alert-danger">{error}</div>}{" "}
//             {/* Display error if exists */}
//             <form onSubmit={handleSubmit}>
//               <div className="login-item col-lg-8 mx-auto">
//                 <label htmlFor="email">Username</label>
//                 <input
//                   type="email"
//                   placeholder="Enter your email"
//                   name="email"
//                   value={email} // Bind email state
//                   onChange={(e) => setEmail(e.target.value)} // Update email state
//                   required
//                 />
//               </div>
//               <div className="login-item col-lg-8 mx-auto">
//                 <label htmlFor="password">Password</label>
//                 <div className="input-group login-group">
//                   <input
//                     type={showPassword ? "text" : "password"}
//                     className="form-control"
//                     placeholder="Enter your password"
//                     aria-label="password"
//                     aria-describedby="basic-addon2"
//                     name="password"
//                     value={password} // Bind password state
//                     onChange={(e) => setPassword(e.target.value)} // Update password state
//                     required
//                   />
//                   <div className="input-group-append login-append">
//                     <span className="input-group-text" id="basic-addon2">
//                       <i
//                         className={
//                           showPassword ? "bi bi-eye-slash" : "bi bi-eye"
//                         }
//                         onClick={handleVisibility}
//                       ></i>
//                     </span>
//                   </div>
//                 </div>
//               </div>
//               <div className="login-button col-lg-8 mx-auto">
//                 <button type="submit">Login</button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Login;
import iziToast from 'izitoast';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';


const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { apipath } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        `${apipath}/users/admin/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData)
        }
      );

      const result = await response.json();
      console.log('Login response:', result);
      
      if (result.success) {
        const token = result.token;
        if (!token || token.split('.').length !== 3) {
          throw new Error('Invalid token received');
        }
        // localStorage.setItem("token", token);

        iziToast.success({
          message: "Login successful",
          position: "topCenter"
        });

        if (result.user.role === 'admin') {
          localStorage.setItem('admintoken', token);
          localStorage.setItem('adminname', result.user.name);
          localStorage.setItem('adminemail', result.user.email);
          localStorage.setItem('adminId', result.user._id);
          navigate("/admin/dashboard");
        } 
      } else {
        iziToast.error({
          message: `Login failed: ${result.error}`,
          position: "topCenter"
        });
        console.error("Login failed:", result.error);
      }
    } catch (error) {
      iziToast.error({
        message: "Error during login",
        position: "topCenter"
      });
      console.error("Error during login:", error);
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="container-fluid login">
      <div className="row login-row">
        <div className="col-lg-6">
          <div className="img-login">
            <img src="/images/Mobile login-rafiki.png" alt="Login illustration" />
          </div>
        </div>
        <div className="col-lg-6 login-right">
          <h1>Admin Login</h1>
          <p>Welcome!</p>
          <form onSubmit={handleSubmit}>
            <div className="login-item col-lg-8 mx-auto">
              <label htmlFor="email">Username</label>
              <input
                type="email"
                placeholder="Enter your email"
                name="email"
                onChange={handleChange}
                required
              />
            </div>
            <div className="login-item col-lg-8 mx-auto">
              <label htmlFor="password">Password</label>
              <div className="input-group login-group">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  placeholder="Enter your password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <div className="input-group-append login-append">
                  <span className="input-group-text" onClick={handleVisibility}>
                    <i className={showPassword ? "bi bi-eye-slash" : "bi bi-eye"}></i>
                  </span>
                </div>
              </div>
              {/* <div className="forgot d-flex justify-content-end">
                <Link to="/forgot" className="text-decoration-none">
                  <p>Forgot Password?</p>
                </Link>
              </div> */}
            </div>
            <div className="login-button col-lg-8 mx-auto">
              <button type="submit">Login</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
