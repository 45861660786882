import React from "react";

const Modal1 = ({ isOpen, onClose, report }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-lg p-6 shadow-lg max-w-lg w-full">
        <h2 className="text-xl font-semibold mb-4">
          {report.name} - Report Preview
        </h2>
        <p className="mb-4">Report completed on: {report.date}</p>
        <p className="text-lg font-semibold">Price: ₹ {report.price}</p>
        <div className="mt-4">
          {/* You can display the report content here, e.g., an image or PDF viewer */}
          <p>This is where the report details would be displayed.</p>
          {/* Replace with actual report content */}
        </div>
        <button
          onClick={onClose}
          className="mt-4 bg-red-500 text-white px-4 py-2 rounded-lg"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal1;
