import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import NewsLetter from "../landingPage/NewsLetter";
import Footer from "../layout/Footer";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import { Dialog } from "@headlessui/react";
import { FaPlus } from "react-icons/fa";
import axiosClient from "../../axios-client";
import { useNavigate } from "react-router-dom";

function SavedAddresses() {
  const [savedAddresses, setSavedAddresses] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [currentAddress, setCurrentAddress] = useState({
    save_as: "",
    house_no: "",
    street: "",
    landmark: "",
    city: "",
    state: "",
    pincode: "",
  });
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/admin/login"); // Redirect if no token
    }
  }, [navigate]);

  useEffect(() => {
    fetchAddresses();
  }, []);

  const userId =  localStorage.getItem("userId");

  const fetchAddresses = async () => {
    try {
      const response = await axiosClient.get(`/users/addresses/${userId}`
      );
      setSavedAddresses(response.data.addresses);
    } catch (error) {
      console.error("Error fetching addresses:", error);
    }
  };

  const handleAddAddressClick = () => {
    setCurrentAddress({
      save_as: "",
      house_no: "",
      street: "",
      landmark: "",
      city: "",
      state: "",
      pincode: "",
    });
    setIsAdding(true); // Open modal for adding new address
  };

  const handleEdit = (address) => {
    setCurrentAddress(savedAddresses[address]);
    setEditIndex(address._id);
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      if (
        !currentAddress.house_no ||
        !currentAddress.city ||
        !currentAddress.pincode
      ) {
        alert(
          "Please fill in the required fields: House No., City, and Pincode."
        );
        return;
      }

      if (isEditing) {
        await axiosClient.put(`/users/address/${currentAddress._id}`, {
          ...currentAddress,
          userId: localStorage.getItem("userId")
        });
      } else {
        await axiosClient.post(`/users/address`, {
          ...currentAddress,
          userId: localStorage.getItem("userId")
        });
      }

      await fetchAddresses();
      setIsEditing(false);
      setIsAdding(false);
    } catch (error) {
      console.error("Error saving address:", error);
    }
  };

  const handleDelete = () => {
    const updatedAddresses = savedAddresses.filter(
      (_, index) => index !== editIndex
    );
    setSavedAddresses(updatedAddresses);
    setIsEditing(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentAddress({ ...currentAddress, [name]: value });
  };

  return (
    <>
      <Navbar />
      <Navbar2 />
      <div className="max-w-screen-2xl mx-auto md:py-14 lg:py-20 py-8 lg:px-20 md:px-12 px-4">
        <div className="flex border-2 border-gray-300 shadow-lg rounded-3xl">
          {/* Sidebar */}
          <Sidebar />

          {/* Main Content */}
          <main className="w-3/4 p-8">
            <div className="flex justify-between items-center">
              <h1 className="text-2xl font-semibold font-museo font4 pb-5">
                Saved Addresses
              </h1>
            </div>

            <div className="space-y-4">
              {savedAddresses.map((address, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between p-4 font-museo shadow-sm bg-gray-50 border-b border-b-red-600"
                >
                  <div className="">
                    <h3 className="text-lg font-bold font4">
                      {address.save_as || "New Address"}
                    </h3>
                    <div className="flex gap-1 ">
                      <p className="font7">{address.house_no || "N/A"},</p>
                      <p className="font7">{address.street || "N/A"},</p>
                      <p className="font7">{address.landmark || "N/A"},</p>
                      <p className="font7">{address.city || "N/A"},</p>
                      <p className="font7">{address.state || "N/A"}</p>
                      <p className="font7">{address.pincode || "N/A"}</p>
                    </div>
                  </div>
                  <button
                    onClick={() => handleEdit(index)}
                    className="bg-card1 text-white px-4 py-2 rounded-md hover:bg-red-600"
                  >
                    Edit
                  </button>
                </div>
              ))}
            </div>
            <button
              onClick={handleAddAddressClick}
              className="mt-4 bg-card1 font-museo text-white px-4 py-2 rounded-md flex items-center space-x-2 hover:bg-red-600"
            >
              <FaPlus />
              <span>Add More Addresses</span>
            </button>

            {/* Modal for Adding or Editing Address */}
            <Dialog
              open={isEditing || isAdding}
              onClose={() => {
                setIsEditing(false);
                setIsAdding(false);
              }}
              className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-30 transition-opacity duration-300"
            >
              <Dialog.Panel className="bg-white p-6 rounded-lg shadow-md w-full max-w-lg">
                <Dialog.Title className="text-xl font-bold mb-4">
                  {isEditing ? "Edit Address" : "Add New Address"}
                </Dialog.Title>
                <div className="space-y-4">
                  <input
                    name="save_as"
                    value={currentAddress.save_as}
                    onChange={handleInputChange}
                    placeholder="Save As"
                    className="w-full p-2 border rounded-lg"
                  />
                  <input
                    name="house_no"
                    value={currentAddress.house_no}
                    onChange={handleInputChange}
                    placeholder="House No."
                    className="w-full p-2 border rounded-lg"
                  />
                  <input
                    name="street"
                    value={currentAddress.street}
                    onChange={handleInputChange}
                    placeholder="Street"
                    className="w-full p-2 border rounded-lg"
                  />
                  <input
                    name="landmark"
                    value={currentAddress.landmark}
                    onChange={handleInputChange}
                    placeholder="Landmark"
                    className="w-full p-2 border rounded-lg"
                  />
                  <input
                    name="city"
                    value={currentAddress.city}
                    onChange={handleInputChange}
                    placeholder="City"
                    className="w-full p-2 border rounded-lg"
                  />
                  <input
                    name="state"
                    value={currentAddress.state}
                    onChange={handleInputChange}
                    placeholder="State"
                    className="w-full p-2 border rounded-lg"
                  />
                  <input
                    name="pincode"
                    value={currentAddress.pincode}
                    onChange={handleInputChange}
                    placeholder="Pincode"
                    className="w-full p-2 border rounded-lg"
                  />
                </div>
                <div className="mt-4 flex justify-between items-center">
                  <div className="flex space-x-2">
                    <button
                      onClick={() => {
                        setIsEditing(false);
                        setIsAdding(false);
                      }}
                      className="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSave}
                      className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Dialog>
          </main>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
}

export default SavedAddresses;
