import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";

const Test = () => {
  const dataTableRef = useRef();
  const [show, setShow] = useState(false);
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [selectedTestId, setSelectedTestId] = useState(null);

  useEffect(() => {
    const fetchTests = async () => {
      try {
        const response = await axiosClient.get("tests/tests");
        setTests(response.data.test);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch tests. Please try again.");
        setLoading(false);
      }
    };

    fetchTests();
  }, []);

  useEffect(() => {
    if (dataTableRef.current) {
      // Destroy the previous DataTable instance if it exists
      if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
        $(dataTableRef.current).DataTable().destroy();
      }

      // Initialize DataTable again with new data
      $(dataTableRef.current).DataTable();
    }
  }, [tests]); // Trigger DataTable when tests change

  const handleShow = (testId) => {
    setSelectedTestId(testId);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const deleteTest = async () => {
    try {
      await axiosClient.delete(`tests/test/${selectedTestId}`);
      setTests(tests.filter((test) => test._id !== selectedTestId));
      setShow(false);
      setSuccess("Test deleted successfully!");
      setError("");
    } catch (err) {
      setError("Failed to delete the test. Please try again.");
      setSuccess("");
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Test</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this test?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={deleteTest}>
              Delete
            </button>
          </Modal.Footer>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <h5>All Tests</h5>
            {error && <div className="alert alert-danger">{error}</div>}
            {success && <div className="alert alert-success">{success}</div>}
            <div className="add-button">
              <Link to="/admin/add-test">
                <button>+ Add Test</button>
              </Link>
            </div>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Test Title</th>
                      <th>Price</th>
                      <th>Fasting Time</th>
                      <th>Report Time</th>
                    
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(tests || []).map((test, index) => (
                      <tr key={test._id}>
                        <td>{index + 1}</td>
                        <td>{test.title}</td>
                        <td>₹{test.price.toFixed(2)}</td>
                        <td>{test.fastingTime}</td>
                        <td>{test.reportTime}</td>
                      
                        <td>
                          <Link to={`/admin/edit-test/${test._id}`}>
                            <i className="fa-regular fa-pen-to-square"></i>
                          </Link>
                          &nbsp;
                          <i
                            className="fa-solid fa-trash"
                            onClick={() => handleShow(test._id)}
                          ></i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Test;
