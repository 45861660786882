import { useState } from "react";
import CompletedTests from "./CompletedTests";
import TrackProgress from "./TrackProgress";

const BookingsAndReports = () => {
  const [activeTab, setActiveTab] = useState("upcoming");

  const tabs = [
    { name: "Upcoming Tests", value: "upcoming" },
    { name: "Past Tests", value: "past" },
    { name: "Completed Tests", value: "completed" },
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case "upcoming":
        return <TrackProgress />;
      case "past":
        return <PastTests />;
      case "completed":
        return <CompletedTests />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="border-b border-gray-200 my-6">
        <nav className="-mb-px flex flex-wrap space-x-4 md:space-x-8" aria-label="Tabs">
          {tabs.map((tab) => (
            <button
              key={tab.value}
              onClick={() => setActiveTab(tab.value)}
              className={`whitespace-nowrap pb-4 border-b-2 py-2 px-4 text-lg font-semibold ${
                activeTab === tab.value
                  ? "border-b-2 border-colorb font-museo"
                  : "font7"
              }`}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
      <div className="mt-6">{renderTabContent()}</div>
    </>
  );
};

const PastTests = () => <div>No past tests available.</div>;

export default BookingsAndReports;
