import React from "react";

function Instructions({ offers }) {
  return (
    <div className="max-w-screen-2xl mx-auto py-8 px-4 md:px-12 md:py-14 lg:px-20 lg:py-20">
      <div>
        <h3 className="font-museo font4 text-xl md:text-2xl font-semibold">
          Package Instructions
        </h3>

        <span className="flex mt-4 md:mt-6">
          <img src={`${process.env.PUBLIC_URL}/others/star-svgrepo-com.svg`} alt="df" width="16px" />
          <p className="font-mont font7 text-sm md:text-base lg:text-lg ml-2 font-medium">
            {offers.instructions}
          </p>
        </span>
      </div>

      <div className="flex flex-col gap-4 py-6 md:flex-row md:py-8 lg:py-8">
        {/* First Card */}
        <div className="flex flex-col md:flex-row justify-center bg-card2 rounded-lg py-6 lg:pl-6 md:pl-3 pl-3 shadow-lg border-2 border-gray-300 lg:h-[368px] md:h-[300px]">
          <div className="lg:w-3/5">
            <h2 className="text-lg lg:text-xl font-bold font-museo font4 mb-2">
              {offers.title}{" "}
              <span className="font6">{offers.subTitle}</span>
            </h2>
            <p className="font4 mb-4 lg:w-3/4 text-sm lg:text-base font-mont font-medium line-clamp-3">
              It assesses various health aspects, including Vitamin Screening
              and free HsCRP to detect problems early.
            </p>
            <div className="font7 font-mont font-medium text-sm md:mb-2 mb-4 lg:mb-4">
              <span className="mr-3 lg:mr-5">
                <span className="font6">95</span> Parameters
              </span>
              <span>
                <span className="font6">{offers.reportTime}</span> Reports
              </span>
            </div>
            <div className="flex items-center bg-white w-[60%] md:w-3/4 px-4 py-2 rounded-lg font-museo">
              <span className="font-semibold lg:text-lg text-base font6">
                ₹{offers.price}
              </span>
              <span className="ml-2 text-xs font5">70% off</span>
            </div>
          </div>
          <div className=" mt-4 md:mt-44 lg:mt-0 relative">
            <img
              src={`${process.env.PUBLIC_URL}/young.png`}
              alt="Family Checkup"
              className="w-[150px] md:w-[300px] lg:w-[300px] h-auto relative z-50 pr-5"
            />
            <img
              src={`${process.env.PUBLIC_URL}/Vector 7354.png`}
              alt="Background"
              className="w-[150px] md:w-[300px] lg:w-[300px] h-auto relative lg:bottom-10 md:bottom-6 z-0"
            />
          </div>
        </div>

        {/* Second Card */}
        <div className="flex flex-col md:flex-row justify-center bg-card3 rounded-lg py-6 lg:pl-6 md:pl-3 pl-3  shadow-lg border-2 border-gray-300 lg:h-[368px] md:h-[300px]">
          <div className="lg:w-3/5">
            <h2 className="text-lg lg:text-xl font-bold font-museo font4 mb-2">
              {offers.title}{" "}
              <span className="font6">{offers.subTitle}</span>
            </h2>
            <p className="font4 mb-4 text-sm lg:text-base font-mont font-medium line-clamp-3 w-[90%] lg:w-3/4">
              It assesses various health aspects, including Vitamin Screening
              and free HsCRP to detect problems early.
            </p>
            <div className="font7 font-mont font-medium text-sm md:mb-2 mb-4 lg:mb-4">
              <span className="mr-3 lg:mr-5">
                <span className="font6">95</span> Parameters
              </span>
              <span>
                <span className="font6">{offers.reportTime}Hrs</span> Reports
              </span>
            </div>
            <div className="flex items-center bg-white w-[60%] md:w-3/4 px-4 py-2 rounded-lg font-museo">
              <span className="font-semibold lg:text-lg text-base font6">
                ₹{offers.price}
              </span>
              <span className="ml-2 text-xs font5">70% off</span>
            </div>
          </div>
          <div className="mt-4 lg:mt-0 md:mt-44 relative">
            <img
              src={`${process.env.PUBLIC_URL}/man.png`}
              alt="Family Checkup"
              className="w-[150px] md:w-[300px] lg:w-[300px] h-auto relative z-50 pr-5"
            />
            <img
              src={`${process.env.PUBLIC_URL}/Vector blue.png`}
              alt="Background"
              className="w-[150px] md:w-[300px] lg:w-[300px] h-auto relative lg:bottom-10 md:bottom-6 z-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Instructions;
