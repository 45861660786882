import React, { createContext, useContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [cart, setCart] = useState([]);

  // const apipath = "http://localhost:4000/api/v1";
  // const filepath = "http://localhost:4000/images/";

  const apipath = "https://labs.drpalvehospital.com:6060/api/v1";
  const filepath = "https://labs.drpalvehospital.com:6060/images/";

  const login = () => {
    setIsLoggedIn(true);
  };

  const adminLogout = () => {
    localStorage.removeItem("admintoken");
    localStorage.removeItem("adminname");
    localStorage.removeItem("adminemail");
    localStorage.removeItem("adminId");
    setIsLoggedIn(false);
    window.location.href = "/";
  };

  const addToCart = (testId) => {
    setCart((prevCart) => {
      if (!prevCart.includes(testId)) {
        return [...prevCart, testId];
      }
      return prevCart;
    });
  };

  const removeFromCart = (testId) => {
    setCart((prevCart) => prevCart.filter((id) => id !== testId));
  };

  const isInCart = (testId) => {
    return cart.includes(testId);
  };

  const checkTokenExpiration = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp < currentTime) {
        adminLogout();
      }
    }
  };

  useEffect(() => {
    checkTokenExpiration();
    const interval = setInterval(() => {
      checkTokenExpiration();
    }, 60000); // Check every 1 minute

    return () => clearInterval(interval);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        apipath,
        login,
        adminLogout,
        filepath,
        cart,
        addToCart,
        removeFromCart,
        isInCart,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
