import React from "react";
import Navbar from "../layout/Navbar";
import Navbar2 from "../layout/Navbar2";
import Footer from "../layout/Footer";
import NewsLetter from "../landingPage/NewsLetter";

function Contact() {
  return (
    <>
      <Navbar />
      <Navbar2 />

      <div className="flex justify-center items-center bg-gray-100 py-8">
        {/* Container with responsive styles */}
        <div className="bg-white flex flex-col md:flex-row mx-4 w-full max-w-[1196px] h-auto rounded-[16px] shadow-lg shadow-gray-300 relative"> {/* Added shadow classes */}
          {/* Left Side - Contact Information */}
          <div className="relative w-full md:w-[498px] h-auto md:h-[667px] border-l-8 border-l-[#1E4FA6] rounded-l-[16px] overflow-hidden">
            {/* Image with rounded corners only on the left */}
            <div
              className="absolute inset-0 w-full h-full bg-cover bg-center"
              style={{
                backgroundImage: `url('/images/Rectangle 3841.png')`, 
              }}
            ></div>
            {/* Semi-transparent overlay */}
            <div className="relative z-10 p-14 flex flex-col items-center h-full">
              <div className="flex flex-col items-center mb-4 md:-mb-14 -ml-24">
                <h1 className="text-xl font-bold text-white text-center mb-2 font-museo">
                  Contact Information
                </h1>
                <p className="text-gray-100 text-sm text-center montserrat">
                  Say something if you have a doubt
                </p>
              </div>
              <div className="flex flex-col items-center justify-center h-full space-y-4">
                <div className="flex items-center text-white justify-between w-full">
                  <i className="fas fa-phone mr-1 flex-shrink-0 ml-5"></i>
                  <span className="flex-1 text-left ml-8 Montserrat">
                    +91 9898989898
                  </span>
                </div>
                <div className="flex items-center text-white justify-between w-full">
                  <i className="fas fa-envelope mr-1 flex-shrink-0 ml-5"></i>
                  <span className="flex-1 text-left ml-8 montserrat">
                    info@drPalveHospital.com
                  </span>
                </div>
                <div className="flex items-center text-white justify-between w-full">
                  <i className="fas fa-map-marker-alt mr-1 flex-shrink-0 ml-5"></i>
                  <span className="flex-1 text-left ml-8">
                    Plot No.54/4/4/1, Kamla Nagar, Delhi, New Delhi, 210001,
                    India
                  </span>
                </div>
                <div className="flex items-center text-white justify-between w-full">
                  <i className="fas fa-globe mr-1 flex-shrink-0 ml-5 mt-5"></i>
                  <span className="flex-1 text-left ml-8 montserrat mt-5">
                    <a
                      href="https://drPalveHospital.com"
                      className="underline text-white"
                    >
                      https://drPalveHospital.com
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* Right Side - Get In Touch Form */}
          <div className="p-11 flex-1 flex flex-col rounded-r-[16px]">
            <h2 className="text-xl font-bold mb-2 font-museo">
              Get In Touch With Us
            </h2>
            <p className="mb-3 Montserrat">
              We are happy to answer any questions you may have regarding our
              services.
            </p>
            <form className="flex-grow flex flex-col">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-7 mb-2 font-mont">
                <div className="mb-4">
                  <label htmlFor="name" className="block mb-1 font-mont">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="w-full px-2 py-2 border-b-2 border-gray-400 focus:border-blue-600 focus:outline-none font-mont"
                    placeholder="Name"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="email" className="block mb-1 font-mont">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="w-full px-2 py-2 border-b-2 border-[#8D8D8D] focus:border-blue-600 focus:outline-none"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-5">
                <div className="mb-1">
                  <label htmlFor="phone" className="block mb-1 font-mont">
                    Phone No.
                  </label>
                  <input
                    type="text"
                    id="phone"
                    className="w-full px-2 py-2 border-b-2 border-[#8D8D8D] focus:border-blue-600 focus:outline-none"
                    placeholder="00000-00000"
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="address" className="block mb-1 font-mont">
                    Address
                  </label>
                  <input
                    type="text"
                    id="address"
                    className="w-full px-2 py-2 border-b-2 border-[#8D8D8D] focus:border-blue-600 focus:outline-none font-mont"
                    placeholder="Address"
                  />
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="subject" className="block mb-1 font-mont">
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  className="w-full md:w-2/4 px-2 py-2 border-b-2 border-[#8D8D8D] focus:border-blue-600 focus:outline-none font-mont"
                  placeholder="Subject"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="block mb-2 font-mont">
                  Message
                </label>
                <textarea
                  id="message"
                  className="w-full px-2 py-2 border-b-2 border-[#8D8D8D] focus:border-blue-600 focus:outline-none font-mont"
                  placeholder="Message"
                ></textarea>
              </div>
              <div className="flex justify-end mt-auto">
                <button
                  type="submit"
                  className="bg-[#1E4FA6] text-white px-6 py-2 font-museo rounded-md"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <NewsLetter />
      <Footer />
    </>
  );
}

export default Contact;
