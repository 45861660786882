import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import axiosClient from "../../axios-client";

const TestParameter = () => {
  const dataTableRef = useRef();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [testParameters, setTestParameters] = useState([]);
  const [success, setSuccess] = useState("");
  const [selectedParameterId, setSelectedParameterId] = useState(null);
  

  useEffect(() => {
    const fetchTestParameters = async () => {
      try {
        const paramsResponse = await axiosClient.get(
          "tests-parameters/test/parameters"
        );
        setTestParameters(paramsResponse.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching test parameters or tests:", error);
        setError("Failed to fetch tests. Please try again.");
        setLoading(false);
      }
    };

    fetchTestParameters();
    // $(dataTableRef.current).DataTable();
  }, []);

  useEffect(() => {
    if (dataTableRef.current) {
      // Destroy the previous DataTable instance if it exists
      if ($.fn.DataTable.isDataTable(dataTableRef.current)) {
        $(dataTableRef.current).DataTable().destroy();
      }

      // Initialize DataTable again with new data
      $(dataTableRef.current).DataTable();
    }
  }, [testParameters]); // Trigger DataTable when tests change

  const handleShow = (id) => {
    setSelectedParameterId(id);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedParameterId(null);
  };

  const handleDelete = async () => {
    if (selectedParameterId) {
      try {
        await axiosClient.delete(
          `tests-parameters/test/parameter/${selectedParameterId}`
        );
        setTestParameters((prev) =>
          prev.filter((param) => param._id !== selectedParameterId)
        );
        setSuccess("Test Parameters deleted successfully!");
        setError("");
        handleClose();
      } catch (error) {
        console.error("Error deleting test parameter:", error);
        setError("Failed to delete the test parameter. Please try again.");
        setSuccess("");
      }
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }
  console.log(testParameters)

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Test</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this test?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleDelete}>
              Delete
            </button>
            <button className="modal-footer-btn" onClick={handleClose}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <h5>All Test Parameters</h5>
            {error && <div className="alert alert-danger">{error}</div>}
            {success && <div className="alert alert-success">{success}</div>}
            <div className="add-button">
              <Link to="/admin/add-test-parameter">
                <button>+ Add Test Parameter</button>
              </Link>
            </div>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Parameter Title</th>
                      <th>Parameters</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testParameters.length > 0 ? (
                      testParameters.map((param, index) => (
                        <tr key={param._id}>
                          <td>{index + 1}</td>
                          <td>{param.title}</td>
                          <td>
                            <select className="form-select" aria-label="Select Parameter">
                              <option value="" disabled selected>
                              Parameter
                              </option>
                              {param.parameters.length > 0 ? (
                                param.parameters.map((parameter, idx) => (
                                  <option key={idx} value={parameter}>
                                    {parameter}
                                  </option>
                                ))
                              ) : (
                                <option disabled>No Parameters Available</option>
                              )}
                            </select>
                        </td>
                          <td>
                            <Link
                              to={`/admin/edit-test-parameter/${param._id}`}
                            >
                              <i className="fa-regular fa-pen-to-square"></i>
                            </Link>{" "}
                            &nbsp;
                            <i
                              className="fa-solid fa-trash"
                              onClick={() => handleShow(param._id)}
                            ></i>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center">
                          No Parameters available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TestParameter;
