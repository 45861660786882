import { useEffect, useState } from "react";
import AddPatientsCart from "./AddPatientsCart";
import axiosClient from "../../axios-client";

const AddPatients = () => {
  const [patients, setPatients] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [currentPatient, setCurrentPatient] = useState({
    name: "",
    relation: "",
    age: "",
    dob: "",
    gender: "",
    phone: "",
    email: "",
  });

  const patientsPerPage = 3;

  const fetchUserData = async () => {
    const userId = localStorage.getItem("userId"); // Get UserId from local storage
    if (userId) {
      try {
        const response = await axiosClient(`/users/details/${userId}`);
        const data = await response.data;
        if (data.success) {
          setPatients(data.user.familyMembers); // Set familyMembers as patients
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  };


  useEffect(() => {
    fetchUserData();
  }, []); // Run once on mount

  const totalPages = Math.ceil(patients.length / patientsPerPage);

  const openEditModal = (patient) => {
    setCurrentPatient(patient);
    setEditModalOpen(true);
  };

  const openAddModal = () => {
    setCurrentPatient(null); // Clear current patient for adding
    setAddModalOpen(true);
  };

  const closeModal = () => {
    setAddModalOpen(false);
    setEditModalOpen(false);
    setCurrentPatient(null); // Reset current patient on close
    fetchUserData();
  };

  const goToNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (currentPatient) {
      setCurrentPatient((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSave = () => {
    if (isEditModalOpen && currentPatient) {
      setPatients((prev) =>
        prev.map((patient) =>
          patient.id === currentPatient.id ? currentPatient : patient
        )
      );
    } else if (isAddModalOpen) {
      setPatients((prev) => [
        ...prev,
        { ...currentPatient, id: prev.length + 1 }, // Assign a new ID
      ]);
    }
    closeModal();
  };
  console.log("Current Patient:", currentPatient);

  const handleRefresh = () => {
    fetchUserData();
  };

  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 py-10">
        {/* Selected Packages */}
        <div className="bg-white border-2 border-gray-300 p-10 rounded-2xl shadow-md">
          <h3 className="text-2xl font-bold mb-4 font-museo">
            Add <span className="font1">Patients</span>
          </h3>

          {/* Display package items */}
          <div className="space-y-4">
            {patients
              .slice(
                currentPage * patientsPerPage,
                (currentPage + 1) * patientsPerPage
              )
              .map((patient) => (
                <div
                  key={patient.id}
                  className="flex justify-between items-center py-3 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500"
                >
                  <div>
                    <h3 className="font-semibold font-museo font4 text-base">
                      {patient.name}
                    </h3>
                    <div className="grid grid-cols-2 gap-2 font-museo mt-2 font6 font-medium">
                      <p>
                        Age: <span className="font7">{patient.age}</span>
                      </p>
                      <p>
                        Email: <span className="font7">{patient.email}</span>
                      </p>
                      <p>
                        Phone No.:<span className="font7">{patient.phone}</span>
                      </p>
                      <p>
                        Gender: <span className="font7">{patient.gender}</span>
                      </p>
                    </div>
                  </div>
                  <button
                    className="bg-card1 text-white px-4 py-2 rounded-md font-museo font-semibold"
                    onClick={() => openEditModal(patient)}
                  >
                    Edit
                  </button>
                </div>
              ))}

            <div className="flex justify-between mt-4">
              <button
                className={`border-color2 font4 hover-color2 hover:text-white px-4 py-2 rounded-md font-museo  ${
                  currentPage === 0 ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={currentPage === 0}
                onClick={goToPrevPage}
              >
                Previous
              </button>
              <button
                className={`border-color2 font4 hover-color2 hover:text-white px-6 py-2 rounded-md font-museo  ${
                  currentPage === totalPages - 1
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
                disabled={currentPage === totalPages - 1}
                onClick={goToNextPage}
              >
                Next
              </button>
            </div>
          </div>

          {/* Add More Patients Button */}
          <div className="flex items-center justify-center mt-4 font-museo font-semibold text-lg">
            <button
              className="w-3/4 mt-6 bg-card1 text-white py-3 rounded-lg"
              onClick={openAddModal}
            >
              + Add More Patients
            </button>
          </div>
        </div>

        <AddPatientsCart
          isAdding={isAddModalOpen}
          isEditing={isEditModalOpen}
          setIsAdding={setAddModalOpen}
          setIsEditing={setEditModalOpen}
          currentPatient={currentPatient}
          handleSave={handleSave}
          onRefresh={handleRefresh}
        />

        {/* Offers Section */}
        <div className="bg-white p-10 rounded-2xl shadow-md border-2 border-gray-300">
          <h3 className="text-2xl font-bold mb-4 font-museo">
            Check <span className="font1">Offers</span>
          </h3>

          {/* Display Offers */}
          <div className="space-y-8">
            {[1, 2].map((offer) => (
              <div
                key={offer}
                className="band-gradient flex items-center justify-center rounded-md p-2"
              >
                <div className="text-white font-museo font-semibold pl-3">
                  <h5>Home Sample Collection Available</h5>
                </div>
                <div className="flex items-center justify-end">
                  <img src="others/nurse.png" alt="df" width={"27%"} />
                </div>
              </div>
            ))}
            <div className="py-3 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500">
              <div className="font-museo text-lg flex items-center justify-between border-d mt-1">
                <p className="font7">Total Amount</p>
                <p className="font7">₹1099</p>
              </div>
              <div className="font-museo text-lg flex items-center justify-between border-d mt-1">
                <p className="font7">Extra Charge </p>
                <p className="font7">₹400</p>
              </div>
              <div className="font-museo text-lg flex items-center justify-between mt-1">
                <p className="font6">Amount to be paid </p>
                <p className="font6">₹1599</p>
              </div>
            </div>
          </div>

          {/* Continue Button */}
          <div className="flex items-center justify-center mt-20 mb-2 font-museo font-semibold text-lg">
            <button className="w-3/4 mt-6 bg-card1 text-white py-3 rounded-lg">
              Continue To Next Step
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPatients;







