import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { Link } from "react-router-dom";
import axiosClient from "../../axios-client";
import { useAuth } from "../../AuthContext";

const HealthPackages = () => {
  const [selectedCheckup, setSelectedCheckup] = useState(null);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [packages, setPackages] = useState([]);
  const { addToCart, isInCart } = useAuth();

  const handleBuyNow = (testId) => {
    addToCart(testId);
  };

  const benefits = [
    {
      id: 1,
      imgSrc: "images/photo2.png",
      title: "Reports Consultation",
    },
    {
      id: 2,
      imgSrc: "images/photo1.png",
      title: "On-time Sample Collection",
    },
    {
      id: 3,
      imgSrc: "images/photo3.png",
      title: "Affordable Pricing",
    },
    {
      id: 4,
      imgSrc: "images/photo4.png",
      title: "Certified Labs",
    },
  ];

  const handleCheckupClick = (checkup) => {
    setSelectedCheckup(checkup);
    setIsSheetOpen(true);
  };

  const closeSheet = () => {
    setIsSheetOpen(false);
  };

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axiosClient(`tests/tests`);
        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }
        const data = await response.data;
        console.log(data);
        setPackages(data.test || []);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    fetchPackages();
  }, []);

  return (
    <div className="py-10 px-4 sm:px-8 lg:px-20 bg-tertiary1 min-h-[665px] max-w-screen-2xl mx-auto">
      <h2 className="lg:text-3xl text-2xl font-bold text-white text-center mb-8 font-museo mt-10">
        Check Our <span className="font6">Top Health Packages</span>
      </h2>

      {/* Swiper Carousel */}
      <div className="max-w-screen-2xl mx-auto relative mt-20">
        <Swiper
          slidesPerView={1}
          spaceBetween={20}
          navigation={{
            nextEl: ".next-but",
            prevEl: ".prev-but",
          }} // Enable navigation
          breakpoints={{
            640: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            1024: { slidesPerView: 3 },
          }}
          modules={[Navigation]}
          className="mySwiper"
        >
          {packages
            .filter((pkg) => pkg.show === true)
            .map((pkg, index) => (
              <SwiperSlide key={index}>
                <div
                  className="w-full sm:w-80 md:w-[230px] lg:w-[100%] lg:h-[340px]  md:h-[330px] cursor-pointer  bg-card3 rounded-lg shadow-lg"
                  onClick={() => handleCheckupClick(pkg)}
                >
                  <div className="w-full h-full lg:py-8 md:py-3 py-6 lg:pl-6 md:pl-2 pl-4  flex flex-col justify-between relative">
                    <h3 className="text-lg font-semibold font-museo font4 mb-2 lg:w-[85%] md:w-full">
                      {pkg.title} <span className="font6">{pkg.subTitle}</span>
                    </h3>

                    {/* Description */}
                    <p className="md:text-xs text-sm font4 mb-4 font-mont lg:w-[50%]  md:w-full line-clamp-3">
                      {pkg.description}
                    </p>

                    {/* Parameters & Report Time */}
                    <div className="flex lg:flex-row md:flex-col flex-row justify-between items-center lg:text-sm text-xs font-mont lg:mb-4 mb-2 lg:w-[50%] w-[60%]">
                      <span className="font6">
                        94<span className="font7">Parameters</span>
                      </span>
                      <p className="font6">
                        {pkg.reportTime}
                        <span className="font7">Reports</span>
                      </p>
                    </div>

                    {/* Price & Discount */}
                    <div className="flex justify-between items-center lg:mb-4 mb-2 w-[50%] bg-white lg:py-2 lg:px-4 p-2 rounded-lg shadow-sm font-museo">
                      <div className="lg:text-lg text-sm font-semibold font6">
                        ₹{pkg.price}
                      </div>
                      <div className="font5 lg:text-sm text-xs font-medium ml-2">
                        60% off
                      </div>
                    </div>

                    {/* Buy Now Button */}
                    <button
                      className="bg-card1 text-white py-2 rounded-md shadow-md hover-color1 font-museo lg:text-base text-xs transition-all w-[30%]"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent opening the details sheet
                        handleBuyNow(pkg._id);
                      }}
                    >
                      {isInCart(pkg._id) ? (
                        <Link to="/steps" className="w-full block">
                          View Cart
                        </Link>
                      ) : (
                        "Buy Now"
                      )}
                    </button>
                    <img
                      src="man.png"
                      alt="Package"
                      className="lg:w-[48%] w-32 h-auto absolute md:bottom-8 bottom-6 right-0 z-10"
                    />
                    <img
                      src="Vector blue.png"
                      alt="Package"
                      className="lg:w-[45%] md:w-32 w-36 h-auto absolute bottom-2 right-0 z-0"
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>

        {/* Custom Navigation Buttons */}
        <div className="absolute left-1/2 -bottom-20 transform -translate-x-1/2 flex space-x-4">
          <div className="prev-but text-white border-white border-2 p-3 rounded-full  hover:bg-white hover:text-blue-400   transition">
            <FiArrowLeft size={20} />
          </div>
          <div className="next-but text-white border-white border-2 p-3 rounded-full  hover:bg-white hover:text-blue-400  transition">
            <FiArrowRight size={20} />
          </div>
        </div>
      </div>

      {/* Custom Sheet (Sidebar) */}
      <div>
        <div
          className={`fixed top-0 right-0 rounded-tl-xl rounded-bl-xl h-full bg-[#CEE0FF] shadow-lg transform transition-transform duration-500 ease-in-out ${
            isSheetOpen ? "translate-x-0" : "translate-x-full"
          } w-full sm:w-64 md:w-80 lg:w-96 z-50 overflow-y-auto max-h-screen`}
        >
          <div className="p-4 font-museo">
            <div className="px-4">
              <div className="text-black text-lg font-semibold">Details</div>
              <button
                className="absolute top-5 right-8 text-black"
                onClick={closeSheet}
              >
                <IoMdClose />
              </button>
              <div className="border-t mb-4 border-gray-400"></div>
            </div>

            {selectedCheckup && (
              <div>
                <div className="p-4">
                  <h3 className="text-lg font-bold text-blue-900">
                    <Link to={`/testDetail/${selectedCheckup._id}`}>
                      {selectedCheckup.title}{" "}
                      <span className="text-red-500">
                        {selectedCheckup.subTitle}
                      </span>
                    </Link>
                  </h3>
                  <div className="flex justify-between items-center text-sm text-gray-600 mb-4 mt-2">
                    <span>
                      <span className="text-red-500">94</span> Parameters
                    </span>
                    <span>
                      <span className="text-red-500">
                        {selectedCheckup.reportTime}Hrs{" "}
                      </span>
                      Reports
                    </span>
                  </div>
                  <div className="flex justify-center items-center mb-4 bg-white rounded-xl p-2 gap-2">
                    <div className="text-lg font-semibold text-red-600">
                      ₹{selectedCheckup.price}
                    </div>
                    <div className="text-sm font-medium text-[#0B234E] mt-2">
                      60% off
                    </div>
                  </div>
                  <button className="bg-[#DF4132] text-white text-center w-full py-2 rounded-lg mt-4 hover:bg-red-600">
                    Add To Cart
                  </button>
                  <div className="text-xl mt-6 text-[#2E3192] font-museo font-bold">
                    What is it Used For ?
                  </div>
                  <div className="text-[#666363] text-md">
                    {selectedCheckup.usedFor}
                  </div>
                </div>
                <div className="mx-auto band-gradient w-full">
                  <div className="flex items-center">
                    <div className="text-white font-museo text-sm font-semibold w-[70%]">
                      <h5>Home Sample Collection Available</h5>
                    </div>
                    <div className="flex items-center justify-end">
                      <img src="others/nurse.png" alt="df" width={"30%"} />
                    </div>
                  </div>
                </div>
                <div className="px-4">
                  <h3 className="font-museo font4 text-xl font-semibold mt-2">
                    Package Instructions
                  </h3>
                  <span className="flex ">
                    {" "}
                    <img
                      src="others/star-svgrepo-com.svg"
                      alt="df"
                      width="16px"
                    />{" "}
                    <p className="font-mont font7 text-base ml-2 font-medium mt-1">
                      {selectedCheckup.instructions}
                    </p>
                  </span>
                </div>
                <div className="mx-auto p-4 band-gradient text-white">
                  <h3 className="font-museo text-xl font-semibold pb-10 text-white">
                    Benefits:
                  </h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
                    {benefits.map((benefit) => (
                      <div
                        key={benefit.id}
                        className="text-white rounded-lg flex items-center justify-start shadow-lg"
                      >
                        <div className="bg-white rounded-full p-3">
                          <img
                            src={benefit.imgSrc}
                            alt={benefit.title}
                            className="w-4 h-4"
                          />
                        </div>
                        <h3 className="text-sm font-medium text-center font-mont">
                          {benefit.title}
                        </h3>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Sheet Overlay */}
        {isSheetOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={closeSheet}
          ></div>
        )}
      </div>
    </div>
  );
};

export default HealthPackages;
