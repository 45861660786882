import { useState } from "react";
import ModalCart from "./ModalCart";
import { Link } from "react-router-dom";

const patientsData = [
  {
    id: 1,
    name: "John Doe",
    age: 22,
    email: "abc@xyz.com",
    phone: "8989898989",
    gender: "Male",
  },
  // Add more patient data
];

const packagesData = [
  {
    id: 1,
    name: "Fit India Full Body Checkup With Vitamin Screening With Free HsCRP",
    price: 1099,
  },
  {
    id: 2,
    name: "Fit India Full Body Checkup With Vitamin Screening With Free HsCRP",
    price: 1099,
  },
  // Add more packages
];

const AddPatients = () => {
  const [patients, setPatients] = useState(patientsData);
  const [packages, setPackages] = useState(packagesData);
  const [currentPage, setCurrentPage] = useState(0);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isEditPackageModalOpen, setEditPackageModalOpen] = useState(false);
  const [isEditAddressModalOpen, setEditAddressModalOpen] = useState(false);
  const [isEditDateTimeModalOpen, setEditDateTimeModalOpen] = useState(false);
  const [currentPatient, setCurrentPatient] = useState(null);
  const [currentPackage, setCurrentPackage] = useState(null);
  const [reportCopy, setReportCopy] = useState("");

  const patientsPerPage = 3;

  const openEditModalForPackage = (pkg) => {
    setCurrentPackage(pkg);
    setEditPackageModalOpen(true);
  };

  const openEditModal = (patient) => {
    setCurrentPatient(patient);
    setEditModalOpen(true);
  };

  const openAddModal = () => {
    setAddModalOpen(true);
  };

  const openEditAddressModal = () => {
    setEditAddressModalOpen(true);
  };

  const openEditDateTimeModal = () => {
    setEditDateTimeModalOpen(true);
  };

  const closeModal = () => {
    setAddModalOpen(false);
    setEditModalOpen(false);
    setEditPackageModalOpen(false);
    setEditAddressModalOpen(false);
    setEditDateTimeModalOpen(false);
  };

  return (
    <div className="max-w-7xl mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 py-10">
        {/* Left Side Container for Packages and Patients */}
        <div className="bg-white border-2 border-gray-300 p-6 lg:p-10 rounded-2xl shadow-md">
          {/* Selected Packages */}
          <h3 className="text-2xl lg:text-3xl font-bold mb-4 font-museo">
            Review <span className="font1">Tests</span>
          </h3>

          {/* Display selected packages */}
          <div className="space-y-4">
            {packages.slice(0, 3).map((item) => (
              <div
                key={item.id}
                className="flex justify-between items-center py-3 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500"
              >
                <div>
                  <h4 className="font-semibold font-museo font4 text-base lg:text-lg">
                    {item.name}
                  </h4>
                  <p className="font6 font-medium mt-2 font-museo">
                    ₹{item.price}
                  </p>
                </div>
                <button
                  className="bg-card1 text-white px-4 py-2 rounded-md font-museo font-semibold"
                  onClick={() => openEditModalForPackage(item)}
                >
                  Edit
                </button>
              </div>
            ))}
          </div>

          {/* Add Patients Section */}
          <h3 className="text-2xl lg:text-3xl font-bold mt-10 mb-4 font-museo">
            Review <span className="font1">Patients Details</span>
          </h3>

          {/* Display patient items */}
          <div className="space-y-4">
            {patients
              .slice(
                currentPage * patientsPerPage,
                (currentPage + 1) * patientsPerPage
              )
              .map((patient) => (
                <div
                  key={patient.id}
                  className="flex justify-between items-center py-3 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500"
                >
                  <div>
                    <h3 className="font-semibold font-museo font4 text-base lg:text-lg">
                      {patient.name}
                    </h3>
                    <div className="grid grid-cols-2 gap-2 font-museo mt-2 font6 font-medium">
                      <p>
                        Age: <span className="font7">{patient.age}</span>
                      </p>
                      <p>
                        Email: <span className="font7">{patient.email}</span>
                      </p>
                      <p>
                        Phone No.:{" "}
                        <span className="font7">{patient.phone}</span>
                      </p>
                      <p>
                        Gender: <span className="font7">{patient.gender}</span>
                      </p>
                    </div>
                  </div>
                  <button
                    className="bg-card1 text-white px-4 py-2 rounded-md font-museo font-semibold"
                    onClick={() => openEditModal(patient)}
                  >
                    Edit
                  </button>
                </div>
              ))}
          </div>

          {/* Review Address Section */}
          <h3 className="text-2xl lg:text-3xl font-bold mt-10 mb-4 font-museo">
            Review <span className="font1">Address</span>
          </h3>

          {/* Address Display */}
          <div className="space-y-4">
            <div className="flex justify-between items-center py-3 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500">
              <div className="flex flex-col">
                <p className="font-semibold font-museo font4 text-base lg:text-lg">
                  Home
                </p>
                <p className="mt-2 font-museo font7">
                  22, Baker’s Street, Kamla Nagar, New Delhi
                </p>
                <p className="mt-2 font-museo font7"> 281000</p>
              </div>
              <button
                className="bg-card1 text-white px-4 py-2 rounded-md font-museo font-semibold"
                onClick={openEditAddressModal}
              >
                Edit
              </button>
            </div>
          </div>

          {/* Review Date & Time Section */}
          <h3 className="text-2xl lg:text-3xl font-bold mt-10 mb-4 font-museo ">
            Review <span className="font1">Date & Time</span>
          </h3>

          {/* Date & Time Display */}
          <div className="space-y-4">
            <div className="flex justify-between items-center py-3 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500">
              <div className="flex flex-col">
                <p className="font-semibold font-museo font4 text-base lg:text-lg">
                  18 August 2024
                </p>
                <p className="mt-2 font-museo font7">4:00 PM - 5:00 PM</p>
              </div>
              <button
                className="bg-card1 text-white px-4 py-2 rounded-md font-museo font-semibold"
                onClick={openEditDateTimeModal}
              >
                Edit
              </button>
            </div>
          </div>

          {/* Get Physical Copy Radio Button */}
          <div className="flex items-center mt-4">
            <input
              type="radio"
              id="physical-copy"
              name="report-copy"
              value="physical"
              className="mr-3 w-4 h-4 border border-red-500 rounded-full checked:bg-red-500 checked:border-red-500 focus:outline-red-600 cursor-pointer focus:outline-2 focus:outline-offset-2"
              checked={reportCopy === "physical"}
              onChange={() => setReportCopy("physical")}
            />
            <label
              htmlFor="physical-copy"
              className="ml-2 text-lg text-[#DF4132] font-museo"
            >
              Get Physical Copy of Report (Extra ₹150)
            </label>
          </div>
        </div>

        {/* Offers Section */}
        <div className="bg-white p-6 lg:p-10 rounded-2xl shadow-md border-2 border-gray-300 mt-6 lg:mt-0">
          <h3 className="text-2xl lg:text-3xl font-bold mb-4 font-museo">
            Check <span className="font1">Offers</span>
          </h3>

          {/* Display Offers */}
          <div className="space-y-6">
            {[1, 2].map((offer) => (
              <div
                key={offer}
                className="band-gradient flex items-center justify-center rounded-md p-2"
              >
                <div className="text-white font-museo font-semibold">
                  <h5>Home Sample Collection Available</h5>
                </div>
                <div className="flex items-center justify-end">
                  <img src="others/nurse.png" alt="df" width={"27%"} />
                </div>
              </div>
            ))}
            {/* Gift Card Section */}
            <div className="border-2 border-gray-300 p-5 mt-5 rounded-lg">
              <h4 className="text-xl text-[#1E4FA6] font-museo font-semibold ">
                Pay via Gift Card
              </h4>
              <div className="flex space-x-3">
                <input
                  type="text"
                  placeholder="Enter Code"
                  className="w-full py-3 px-5  border-b-2 border-b-red-500 my-3 font-museo font7 focus:ring-2 focus:ring-gray-200 focus:outline-none"
                />
                <button className="bg-card1 text-white h-10 w-24 rounded-md font-museo font-semibold flex items-center justify-center">
                  Apply
                </button>
              </div>
            </div>

            {/* Payment Method Section */}
            <div className="border-2 border-gray-300 p-6 mt-8 rounded-lg">
              <h4 className="text-xl font-semibold mb-4 text-[#1E4FA6] font-museo">
                Payment Method
              </h4>
              <div className="flex justify-center">
                <div className="flex space-x-12">
                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      name="payment"
                      value="online"
                      className=" font-museo"
                    />
                    <span className="font-museo text-[#666363]">
                      Pay Online
                    </span>
                  </label>
                  <label className="flex items-center space-x-2">
                    <input
                      type="radio"
                      name="payment"
                      value="cash"
                      className="mr-2"
                    />
                    <span className="font-museo text-[#666363]">
                      Pay by Cash/Card
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <div className="py-3 px-5 bg-[#f8f8f8] border-b-2 border-b-red-500">
              <div className="font-museo text-lg flex items-center justify-between border-d mt-1">
                <p className="font7">Total Amount</p>
                <p className="font7">₹1099</p>
              </div>
              <div className="font-museo text-lg flex items-center justify-between border-d mt-1">
                <p className="font7">Extra Charge </p>
                <p className="font7">₹400</p>
              </div>
              <div className="font-museo text-lg flex items-center justify-between border-d mt-1">
                <p className="font7">Diagnostics Fee </p>
                <p className="font7">₹400</p>
              </div>
              <div className="font-museo text-lg flex items-center justify-between border-d mt-1">
                <p className="font7">Diet & Calorie Tracker </p>
                <p className="font7">₹400</p>
              </div>
              <div className="font-museo text-lg flex items-center justify-between mt-1">
                <p className="font6">Amount to be paid </p>
                <p className="font6">₹2299</p>
              </div>
            </div>
          </div>

          {/* Continue Button */}
          <div className="flex items-center justify-center mt-20 mb-2 font-museo font-semibold text-lg">
            <Link to="/congrats">
              <button className="bg-card1 text-white py-3 px-20 rounded-lg">
                Confirm & Pay
              </button>
            </Link>
          </div>
        </div>
      </div>

      {/* Modal Sections */}
      {isAddModalOpen && (
        <ModalCart onClose={closeModal} title="Add Patient">
          {/* Add patient form */}
        </ModalCart>
      )}

      {isEditModalOpen && currentPatient && (
        <ModalCart
          onClose={closeModal}
          title={`Edit Patient: ${currentPatient.name}`}
        >
          {/* Edit patient form */}
        </ModalCart>
      )}

      {isEditPackageModalOpen && currentPackage && (
        <ModalCart
          onClose={closeModal}
          title={`Edit Package: ${currentPackage.name}`}
        >
          {/* Edit package form */}
        </ModalCart>
      )}

      {isEditAddressModalOpen && (
        <ModalCart onClose={closeModal} title="Edit Address">
          {/* Edit address form */}
        </ModalCart>
      )}

      {isEditDateTimeModalOpen && (
        <ModalCart onClose={closeModal} title="Edit Date & Time">
          {/* Edit date & time form */}
        </ModalCart>
      )}
    </div>
  );
};

export default AddPatients;
